import React from "react";

const measures = ["bi", "od", "os"];

export default () => {
  return (
    <div className="mb-10">
      <table className="u-table">
        {this.props.caption && (
          <caption className="fw-b py-10 ta-l" children={this.props.caption} />
        )}
        <thead>
          <tr>
            {measures.map((measure, i) => <th key={i} measure={measure} />)}
          </tr>
        </thead>
        <tbody>
          <tr>
            {measures.map((measure, m) => (
              <td
                key={m}
                value={
                  this.props.pd && this.props.pd.measure
                    ? this.props.pd.measure
                    : null
                }
              />
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};
