import PropTypes from 'prop-types';
import React from 'react';
import DOM from 'react-dom-factories';

export default class RxCheckStepNav extends React.Component {
  static get propTypes() {
    return {
      handleClickBack: PropTypes.func,
      handleClickEdit: PropTypes.func,
      header: PropTypes.string.isRequired,
      step: PropTypes.number
    }
  }

  renderBack() {
    if (this.props.handleClickBack !== undefined) {
      return DOM.span({className: 'u-section-header__back', onClick: this.props.handleClickBack}, null);
    }
  }

  renderClickEdit() {
    if (this.props.handleClickEdit !== undefined) {
      return DOM.span({className: 'u-section-header__edit', onClick: this.props.handleClickEdit}, null);
    }
  }

  renderStep() {
    if (this.props.step !== undefined) {
      return DOM.span({className: 'u-section-header__step'}, `${this.props.step}.`);
    }
  }

  renderTitle() {
    return DOM.span({className: 'u-section-header__title'}, this.props.header);
  }

  render() {
    return DOM.section({className: 'u-section-header ta-c'},
      this.renderBack(),
      this.renderStep(),
      this.renderClickEdit(),
      this.renderTitle());
  }
}
