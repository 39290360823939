import {CorrectionType} from '../actions';

export const VALID = {valid: true, errors: [], warnings: []};

export const RANGES = {
  error: {
    acuity: {
      min: 10,
      max: 40
    },
    axis: {
      min: 1,
      max: 180
    },
    sph: {
      min: -6.0,
      max: 2.0
    }
  },
  warn: {
    pd: {
      bi: {
        min: 50,
        max: 75
      }
    },
    cyl: {
      diff: 2,
      min: -2,
      max: 2
    },
    sph: {
      diff: 2
    }
  }
};

interface Range {
  min: number;
  max: number;
}

export interface ValidationResponse {
  valid?: boolean;
  errors: string[];
  warnings: string[];
}

export const MESSAGES = {
  REQUIRED: (value) => `Missing required ${value}.`,
  DIOPTER_FORMAT: (value) => `Required ${value} must have sign, initial digit(s), decimal, and quarter.`,
  OPPOSING_SIGNS: (value) => `${value} must have the same sign for both eyes.`,
  OPPOSING_SIGNS_SOFT: (value) => `Please confirm opposing ${value} values.`,
  CYL_AND_AXIS: (value) => `Both ${value}.CYL and ${value}.AXIS required when one is present.`,
  AXIS_RANGE: (value) => `${value}.AXIS must be between ${RANGES.error.axis.min} and ${RANGES.error.axis.max}.`,
  PD_RANGE: `Binocular PD is outside range ${RANGES.warn.pd.bi.min}-${RANGES.warn.pd.bi.max} mm.`,
  SPH_RANGE: (value) => `${value}.SPH outside of range ${RANGES.error.sph.min} to
    ${RANGES.error.sph.max > 0 ? '+' : ''}${RANGES.error.sph.max}.`,
  CYL_RANGE: (value) => `${value}.CYL outside of range ${RANGES.warn.cyl.min} to
    ${RANGES.warn.cyl.max > 0 ? '+' : ''}${RANGES.warn.cyl.max}.`,
  ACUITY_ALLOWED_VALUES: 'Select acuity.',
  ACUITY_CORRECTION_TYPE: (value: string, whitelist: CorrectionType[]) => `Because the previous
    prescription is set to ${value}, acuity correction type must be ${whitelist.join(' or ')}.`,
  ACUITY_RANGE: `Selected acuity is > ${RANGES.error.acuity.max}.`,
  SPH_DIFFERENCE: `Difference between SPH values is > ${RANGES.warn.sph.diff}.`,
  CYL_DIFFERENCE: `Difference between CYL values is > ${RANGES.warn.cyl.diff}.`
};

export function isEmpty(value: string): boolean {
  return value === undefined || value === null || value.length === 0;
}

export function inRange(range: Range, num: number | string): boolean {
  num = Number(num);
  if (num === NaN) {
    return false;
  }
  return num >= range.min && num <= range.max;
}

export function aggregate(responses: Array<ValidationResponse>): ValidationResponse {
  return responses.reduce((acc, r) => {
    return {
      valid: acc.valid && r.valid,
      errors: acc.errors.concat(r.errors),
      warnings: acc.warnings.concat(r.warnings)
    };
  }, VALID);
}
