/*
 * action types
 */
export enum ActionTypes {
  ERROR,
  FORBIDDEN,
  CLEAR_ERRORS,
  CLEAR_USER,
  REQUEST_USER,
  RECEIVE_USER,
  CLEAR_AUTH_CONTEXT,
  REQUEST_AUTH_CONTEXT,
  RECEIVE_AUTH_CONTEXT,
  CLEAR_PATIENT,
  REQUEST_PATIENT,
  RECEIVE_PATIENT,
  RECEIVE_PATIENT_ENTRY,
  CLEAR_PATIENT_ENTRIES,
  RECEIVE_PATIENT_ENTRIES,
  REQUEST_PRESCRIPTION_REQUEST,
  REQUEST_PRESCRIPTION_REQUESTS,
  RECEIVE_PRESCRIPTION_REQUESTS,
  RECEIVE_PRESCRIPTION_REQUEST,
  CLEAR_PRESCRIPTION_REQUEST,
  REQUEST_LOCATION,
  RECEIVE_LOCATION,
  REQUEST_UPLOADED_RX,
  RECEIVE_UPLOADED_RX,
}

export interface Action {
  type: ActionTypes;
}
export interface AuthAction extends Action {
  authContext: AuthContext;
}
export interface UserAction extends Action {
  user: User;
}
export interface PatientAction extends Action {
  patient: Patient;
}
/*
 * action creators
 */
export const UserActions = {
  requestUser: (): Action => {
    return { type: ActionTypes.REQUEST_USER };
  },
  receiveUser: (user: User): UserAction => {
    return { type: ActionTypes.RECEIVE_USER, user };
  }
};

export const AuthActions = {
  clearAuthContext: (): Action => {
    return { type: ActionTypes.CLEAR_AUTH_CONTEXT };
  },
  requestAuthContext: (): Action => {
    return { type: ActionTypes.REQUEST_AUTH_CONTEXT };
  },
  receiveAuthContext: (authContext: AuthContext): AuthAction => {
    return { type: ActionTypes.RECEIVE_AUTH_CONTEXT, authContext };
  }
};

export const PatientActions = {
  clearPatient: (): Action => {
    return { type: ActionTypes.CLEAR_PATIENT };
  },
  receivePatient: (patient: Patient): PatientAction => {
    return { type: ActionTypes.RECEIVE_PATIENT, patient };
  },
  requestPatient: (): Action => {
    return { type: ActionTypes.REQUEST_PATIENT };
  }
};

export const PatientEntryActions = {
  clearPatientEntries: () => {
    return { type: ActionTypes.CLEAR_PATIENT_ENTRIES };
  },
  receivePatientEntry: (patientEntry: PatientEntry) => {
    return {
      type: ActionTypes.RECEIVE_PATIENT_ENTRY,
      patientEntry: patientEntry
    };
  },
  receivePatientEntries: (entries: PatientEntry[]) => {
    return { type: ActionTypes.RECEIVE_PATIENT_ENTRIES, entries: entries };
  }
};

export const PrescriptionActions = {
  requestPrescriptionRequest: () => {
    return { type: ActionTypes.REQUEST_PRESCRIPTION_REQUEST };
  },
  requestPrescriptionRequests: () => {
    return { type: ActionTypes.REQUEST_PRESCRIPTION_REQUESTS };
  },
  receivePrescriptionRequests: (
    prescriptionRequests: PrescriptionRequest[]
  ) => {
    return {
      type: ActionTypes.RECEIVE_PRESCRIPTION_REQUESTS,
      prescriptionRequests
    };
  },
  receivePrescriptionRequest: (prescriptionRequest: PrescriptionRequest) => {
    return {
      type: ActionTypes.RECEIVE_PRESCRIPTION_REQUEST,
      prescriptionRequest
    };
  },
  clearPrescriptionRequest: () => {
    return { type: ActionTypes.CLEAR_PRESCRIPTION_REQUEST };
  },
  requestUploadedRx: () => {
    return {
      type: ActionTypes.REQUEST_UPLOADED_RX,
    };
  },
  receiveUploadedRx: (json:object) => {
    return { type: ActionTypes.RECEIVE_UPLOADED_RX, uploadedRxImageData: json};
  }
};

export const LocationActions = {
  receiveLocation: (location: string) => {
    return { type: ActionTypes.RECEIVE_LOCATION, userLocation: location };
  },
  requestLocation: () => {
    return { type: ActionTypes.REQUEST_LOCATION };
  }
};

export const ErrorActions = {
  error: error => {
    return { type: ActionTypes.ERROR, error };
  },
  forbidden: () => {
    return { type: ActionTypes.FORBIDDEN };
  },
  clear: () => {
    return { type: ActionTypes.CLEAR_ERRORS };
  }
};

/*
 * interfaces
 */
export interface Role {
  role: string;
}

export interface User {
  email: string;
  user_data: Role;
  can_assign: boolean;
  identity_id: string;
  can_edit_patient_prescription: boolean;
}

export interface AuthProfile {
  email: string;
}

export interface AuthUser {
  profile: AuthProfile;
  access_token: string;
}

export interface AuthContext {
  user: AuthUser;
  // logout: () => void;
}

export interface Patient {
  email: string;
  uid: string;
  first_name: string;
  last_name: string;
}
export interface PatientEntry {
  type: string;
  prescription_request_id?: string;
  uid: string;
  patient_uid?: string;
}
export interface PdPatientEntry extends PatientEntry {
  pd: Pd;
}
export interface RxPatientEntry extends PatientEntry {
  rx: Rx;
}
export interface AcuityPatientEntry extends PatientEntry {
  acuity: Acuity;
}

export interface PatientEntries {
  pd?: PdPatientEntry[];
  acuity?: Acuity[];
  prior_rx?: RxPatientEntry[];
  device_reading?: RxPatientEntry[];
  kiosk_survey?: Survey[];
}

// we only have binocular pd on our device
export interface Pd {
  bi: string;
}
export type CorrectionType = "glasses" | "contacts" | "no_correction";
export interface Acuity {
  acuity_od: string; // e.g. 50 for 20/50
  acuity_os: string;
  correction: CorrectionType;
  time: string;
}
// TODO match this to the form the data is expected
export interface Eye {
  sph: string;
  cyl?: string;
  axis?: string;
}
export interface Rx {
  od: Eye;
  os: Eye;
  issue_date?: string;
  type?: string;
  note?: string;
  notes?: string;
}
export interface PatientNotes {
  note: string;
  user: string; // email
  time: number;
}

export interface RecommendationRefer {
  notes: string;
  reason: string;
}

export interface RecommendationReady {
  notes: string;
  expiration: string;
  rx: Rx;
}

export interface Survey {
  time: string;
  age: string;
  haveGlassesOrContacts: string;
  lastEyeExam: string;
  rateVisionRating: number;
  experiencing: string;
  eyeProblems: string;
  visionHistory: string;
  personalHistory: string;
  familyHistory: string;
  eyeSurgery: string;
  additionalComments: string;
  [prop: string]: any; // All non-settled keys
}

export interface PrescriptionRequest {
  email: string;
  first_name: string;
  last_name: string;
  uid: string;
  patient_uid: string;
  state: string;
  location: string; // store name or null,
  submitted_time: string; // convert to date and reverse sort
  closed_time: string;
  time: string;
  resolution: string;
  status?: string;
}
export interface PrescriptionRequests {
  data?: PrescriptionRequest[];
}
