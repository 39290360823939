import { Rx, Pd } from "../actions";
import {
  aggregate,
  inRange,
  isEmpty,
  MESSAGES,
  RANGES,
  VALID,
  ValidationResponse,
} from "./validation";
import { CorrectionType, Eye } from "../actions";
import * as _ from "lodash";

const diopterFormat = /(0.00)|([+-]\d{1,2}\.(25|00|50|75))/;
const SIGNS = ["+", "-"];
export const MONTHS = [
  { label: "--", value: "" },
  { label: "January", value: "01" },
  { label: "February", value: "02" },
  { label: "March", value: "03" },
  { label: "April", value: "04" },
  { label: "May", value: "05" },
  { label: "June", value: "06" },
  { label: "July", value: "07" },
  { label: "August", value: "08" },
  { label: "September", value: "09" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];
const CURRENT_YEAR = new Date().getUTCFullYear();
const RX_YEAR_COUNT = 6;
export const YEARS = _
  .fill(Array(RX_YEAR_COUNT), null)
  .map((v, i) => CURRENT_YEAR - i);

export interface RxType {
  [key: string]: {
    label: string;
    acuity_correction_whitelist: CorrectionType[];
  };
}

export const rxTypes = {
  prior_neutralized_rx: {
    label: "neutralized",
    acuity_correction_whitelist: ["glasses"],
  },
  prior_glasses_rx: {
    label: "glasses",
    acuity_correction_whitelist: ["glasses", "no_correction"],
  },
  prior_contacts_rx: {
    label: "contacts",
    acuity_correction_whitelist: ["contacts"],
  },
  waiting_for_prior_rx: {
    label: "waiting for prior prescription",
    acuity_correction_whitelist: ["glasses", "contacts", "no_correction"],
  },
};

export function normalizeRxValue(value) {
  // Function to normalize Rx value, because of the various forms of survey entry
  // can accept either String/Number/Null
  //
  // For malformed inputs I'm returning an empty string, feel that is more to be caught
  // either automcatically or visually by a Dr
  if (!(_.isNumber(value) || _.isString(value))) {
    return "";
  }

  const number = Number(value);
  // Cover the badly formed number and empty string cases
  if (_.isNaN(number) || value.toString().trim() === "") {
    return "";
  }
  return `${number > 0 ? "+" : ""}${number.toFixed(2)}`;
}

function normalizeEye(eye: Eye): Eye {
  if (_.isNil(eye)) {
    return eye;
  }
  return {
    ...eye,
    sph: normalizeRxValue(eye.sph),
    cyl: normalizeRxValue(eye.cyl),
  };
}

export function normalizeRxValues(rx: Rx): Rx {
  if (!rx) {
    return rx;
  }
  return {
    ...rx,
    od: normalizeEye(rx.od),
    os: normalizeEye(rx.os),
  };
}

function hasOpposingSigns(left: string, right: string): boolean {
  if (left === "0.00" || right === "0.00") {
    return false;
  }
  if (SIGNS.indexOf(left[0]) >= 0) {
    return left[0] != right[0];
  } else {
    return true;
  }
}
function isValidDiopterFormat(diopter: string): boolean {
  return diopterFormat.test(diopter);
}

// ERRORS
// Order cannot be processed with the following (red warning and submit button should be disabled)
// • Opposing signs (+/-) cylinder
// • Axis not equal to 1-180
// • You need to have an axis value entered if you have a cyl value entered (and vise versa)
// • SPH must be between -6.00 and +2.00
// • Sphere and cyl need + or - sign
// • There needs to be a decimal and number after a whole number
// • Cyl is missing a minor value
// • Needs to be quarter increments
// • Minor value must be in quarter increment
//
// WARNINGS
// Warning when any of the following occur (yellow)
// • Opposing signs (+/-) Sphere
// • Power difference right to left in sphere over +/- 2.00 diopters
// • Power difference right to left in cylinder over +/- 2.00 diopters
// • CYL must be between -2.00 and +2.00
export function validateRx(rx: Rx): ValidationResponse {
  var errors = [];
  var warnings = [];
  for (const item of [
    { value: "OD", eye: rx.od },
    { value: "OS", eye: rx.os },
  ]) {
    const { value, eye } = item;
    if (eye === undefined) {
      errors = errors.concat(MESSAGES.REQUIRED(`${value}.SPH`));
    } else {
      if (isEmpty(eye.sph)) {
        errors = errors.concat(MESSAGES.REQUIRED(`${value}.SPH`));
      } else if (!isValidDiopterFormat(eye.sph)) {
        errors = errors.concat(MESSAGES.DIOPTER_FORMAT(`${value}.SPH`));
      } else if (!inRange(RANGES.error.sph, eye.sph)) {
        errors = [...errors, MESSAGES.SPH_RANGE(value)];
      }
      if (!isEmpty(eye.axis)) {
        if (!inRange(RANGES.error.axis, eye.axis)) {
          errors = errors.concat(MESSAGES.AXIS_RANGE("value"));
        }
        if (!isEmpty(eye.cyl)) {
          if (!isValidDiopterFormat(eye.cyl)) {
            errors = errors.concat(MESSAGES.DIOPTER_FORMAT(`${value}.CYL`));
          } else if (!inRange(RANGES.warn.cyl, eye.cyl)) {
            errors = errors.concat(MESSAGES.CYL_RANGE(value));
          }
        } else {
          errors = errors.concat(MESSAGES.CYL_AND_AXIS(value));
        }
      } else if (!isEmpty(eye.cyl) && Number(eye.cyl) !== 0) {
        errors = errors.concat(MESSAGES.CYL_AND_AXIS(value));
      }
    }
  }
  if (rx.od !== undefined && rx.os !== undefined) {
    if (!isEmpty(rx.od.cyl) && !isEmpty(rx.os.cyl)) {
      if (hasOpposingSigns(rx.od.cyl, rx.os.cyl)) {
        errors = errors.concat(MESSAGES.OPPOSING_SIGNS("CYL"));
      }
      if (!isValidDifference(+rx.od.cyl, +rx.os.cyl, RANGES.warn.cyl.diff)) {
        warnings = warnings.concat(MESSAGES.CYL_DIFFERENCE);
      }
    }

    if (!isEmpty(rx.od.sph) && !isEmpty(rx.os.sph)) {
      if (hasOpposingSigns(rx.od.sph, rx.os.sph)) {
        warnings = warnings.concat(MESSAGES.OPPOSING_SIGNS_SOFT("SPH"));
      }
      if (!isValidDifference(+rx.od.sph, +rx.os.sph, RANGES.warn.sph.diff)) {
        warnings = warnings.concat(MESSAGES.SPH_DIFFERENCE);
      }
    }
  }

  return {
    valid: errors.length == 0,
    errors: errors,
    warnings: warnings,
  };
}

export function validateRxNotEmpty(rx: Rx): ValidationResponse {
  var errors = [];
  for (const item of [
    { value: "OD", eye: rx.od },
    { value: "OS", eye: rx.os },
  ]) {
    const { value, eye } = item;
    if (isEmpty(eye.sph)) {
      errors = errors.concat(MESSAGES.REQUIRED(`${value}.SPH`));
    }

    if (!isEmpty(eye.cyl) && isEmpty(eye.axis)) {
      errors = errors.concat(MESSAGES.CYL_AND_AXIS(value));
    }
  }

  return {
    valid: errors.length == 0,
    errors: errors,
    warnings: [],
  };
}

function isValidDifference(od: number, os: number, permitted: number): boolean {
  return Math.abs(od - os) <= permitted;
}

// WARNINGS
// • If binocular PD = x, then 50<x<75.
//    If there is a case where a doctor prescribes something outside this range,
//    CX/Retail can make that request via a support ticket.
export function validatePd(pd: Pd): ValidationResponse {
  if (isEmpty(pd.bi)) {
    return {
      valid: false,
      errors: [MESSAGES.REQUIRED("PD")],
      warnings: [],
    };
  }
  if (inRange(RANGES.warn.pd.bi, pd.bi)) {
    return VALID;
  }
  return {
    valid: false,
    errors: [],
    warnings: [MESSAGES.PD_RANGE],
  };
}

export function validatePdNotEmpty(pd: Pd): ValidationResponse {
  if (isEmpty(pd.bi)) {
    return {
      valid: false,
      errors: [MESSAGES.REQUIRED("PD")],
      warnings: [],
    };
  }
  return VALID;
}

export function validateMonth(month: string): ValidationResponse {
  if (isEmpty(month) || !_.find(MONTHS, { value: month })) {
    return {
      valid: false,
      errors: [MESSAGES.REQUIRED("Rx month")],
      warnings: [],
    };
  }
  return VALID;
}

export function validateYear(year: string): ValidationResponse {
  if (isEmpty(year) || YEARS.indexOf(Number(year)) < 0) {
    return {
      valid: false,
      errors: [MESSAGES.REQUIRED("Rx year")],
      warnings: [],
    };
  }
  return VALID;
}

export function validateIssueDate(rx: Rx): ValidationResponse {
  switch (rx.type) {
    case "waiting_for_prior_rx" || "device_reading":
      // no issue date required
      return VALID;
    default:
      const issueDate = rx.issue_date;
      if (issueDate === "unknown") {
        // user has selected unknown issue date
        return VALID;
      }
      if (isEmpty(issueDate)) {
        return {
          valid: false,
          errors: [MESSAGES.REQUIRED("issue date")],
          warnings: [],
        };
      }
      const [month, year] = issueDate.split("/");
      return aggregate([validateMonth(month), validateYear(year)]);
  }
}
