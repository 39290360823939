import _ from 'lodash';
import React from 'react';
import DOM from 'react-dom-factories';
import PropTypes from 'prop-types';
import Markdown from './TrustedUnsafeMarkdown';
import PrescriptionTable from './PrescriptionTableWithKeypad';
import FormSelect from './form/FormSelect';
import FormLabel from './form/FormLabel';
import { validateIssueDate, validateMonth, validateRx, validateYear, MONTHS, YEARS } from '../validation/rx';
import { aggregate } from '../validation/validation';
import PriorRxCopy from '../../copy/prior_rx.md'

  const years = [{label: '--', value: ''}].concat(YEARS.map(y => { return {label: `${y}`, value: `${y}`};}));

export default class EditablePriorRx extends React.Component {
  static get propTypes() {
    return {
      lensType: PropTypes.string,
      rx: PropTypes.any,
      rxTypes: PropTypes.object.isRequired,
      isEditing: PropTypes.bool,
      handleClickBack: PropTypes.func,
      submitText: PropTypes.string,
      handleSubmit: PropTypes.func.isRequired,
      uid: PropTypes.string
    }
  }

  static get defaultRxState() {
    return {
      rx: {},
      month: '',
      year: '',
      unknownIssueDate: false,
      rxType: '',
      validation: {
        rx: null
      }
    };
  }

  constructor(props) {
    super(props);
    this.state = _.assign({
      isEditing: false,
      showWarnings: false
    }, EditablePriorRx.defaultRxState);
  }

  componentWillMount() {
    this.setStateFromProps(this.props);
  }

  componentWillReceiveProps(props) {
    this.setStateFromProps(props);
  }

  setStateFromProps(props) {
    const newProps = _.assign({}, _.pick(props, ['rx', 'isEditing']));
    if (newProps.rx === undefined) {
      _.assign(newProps, EditablePriorRx.defaultRxState);
    }

    const issued = _.get(this.props, 'rx.issue_date');
    if (!_.isNil(issued)) {
      if (issued === 'unknown') {
        _.assign(newProps, {unknownIssueDate: true});
      } else {
        const [month, year] = this.props.rx.issue_date.split('/');
        _.assign(newProps, {month, year});
      }
    }
    this.setState(_.assign(newProps, {rxType: _.get(this.props, 'rx.type')}));
  }

  validateRx(rx) {
    const rxValid = validateRx(rx);
    this.updateValidation({rx: rxValid});
    return rxValid;
  }

  validateSubmission(rx) {
    const rxValid = validateRx(rx);
    const dateValid = validateIssueDate(rx);
    var monthValid = {};
    var yearValid = {};
    if (!dateValid.valid) {
      // add year/month validation for display
      monthValid = this.validateMonth(this.state.month);
      yearValid = this.validateYear(this.state.year);
    }
    this.updateValidation({rx: rxValid, issueDate: dateValid, month: monthValid, year: yearValid});
    return aggregate([rxValid, dateValid]);
  }

  validateMonth(month) {
    const monthValid = validateMonth(month);
    this.updateValidation({month: monthValid, issueDate: {}});
    return monthValid;
  }

  validateYear(year) {
    const yearValid = validateYear(year);
    this.updateValidation({year: yearValid, issueDate: {}});
    return yearValid;
  }

  updateValidation(validationValues) {
    const validation = _.get(this.state, 'validation', {});
    const newValidation = {validation: _.assign({}, validation, validationValues)};
    this.setState({validation: _.assign({}, validation, validationValues)});
  }

  handleChangeMonth(month) {
    this.setState({unknownIssueDate: false, month: month});
    return this.validateMonth(month);
  }

  handleChangeYear(year) {
    this.setState({unknownIssueDate: false, year: year});
    return this.validateYear(year);
  }

  handleChangeUnknown(checked) {
    this.updateValidation({year: {}, month: {}, issueDate: {}});
    this.setState({month: '', year: '', unknownIssueDate: checked});
  }

  handleSubmit(event) {
    event.preventDefault();
    let {rx, month, year, unknownIssueDate, rxType} = this.state;
    const data = _.assign({}, {rx: rx, uid: this.props.uid});
    if (unknownIssueDate) {
      _.assign(data.rx, {issue_date: 'unknown', type: rxType});
    } else {
      _.assign(data.rx, {issue_date: `${this.state.month}/${this.state.year}`, type: rxType});
    }
    const validation = this.validateSubmission(data.rx);
    if (validation.valid) {
      this.props.handleSubmit(data).then(this.props.handleClickNext());
    } else if (validation.errors.length === 0) {
      if (_.get(this.state, 'showWarnings', false)) {
        this.props.handleSubmit(data).then(this.props.handleClickNext());
      } else {
        this.setState({showWarnings: true});
      }
    }
  }

  renderButtonListItem(label, handleClick, key) {
    const props = {
      className: 'u-button-list-item tt-c',
      onClick: handleClick
    };
    if (key) {
      props.key = key;
    }

    return DOM.button(props, label);
  }

  renderSave(){
    if (this.props.isEditing) {
      const message = this.state.showWarnings ? 'Submit with warnings' : 'Save and continue';
      return DOM.button({
        className: `button ${this.state.showWarnings ? 'warn' : ''}`,
        key: 'submit-prior-rx',
        type: 'submit',
        disabled: false,
        onClick: this.handleSubmit.bind(this)
      }, message);
    }
  }

  renderRxType() {
    if (_.isNil(this.state.rxType)) {
      const rxTypeLabel = DOM.div(
        null,
        DOM.span(null, 'What type of '),
        DOM.span(
          {className: 'fw-b'},
          `${this.props.lensType ? this.props.lensType+' ' : ''}`
        ),
        DOM.span(null, 'prescription do you want to enter?')
      );

      const rxNote = React.createElement(
        Markdown,
        {unsafeRawMarkdown: PriorRxCopy}
      );

      const neutralizedOption = this.renderButtonListItem(
        'Neutralized',
        () => this.setState({rxType: 'prior_neutralized_rx'}),
        'rx-option-neutralized'
      );
      const sendLaterData = {
        rx: {type: 'waiting_for_prior_rx'},
        uid: this.props.uid
      };
      if (this.props.lensType) {
        sendLaterData.rx.lens_type = this.props.lensType;
      }
      let handleClickSendLater = () => this.props.handleSubmit(
        sendLaterData
      ).then(this.props.handleClickNext());

      const rxOptions = [];
      if (this.props.lensType) {
        // Customize options for contacts or glasses lens types
        if (this.props.lensType !== 'contacts') {
          rxOptions.push(neutralizedOption);
        }
        rxOptions.push(
          this.renderButtonListItem(`Prescription ${this.props.lensType}`,
            () => this.setState({rxType: `prior_${this.props.lensType}_rx`}),
            `rx-option-${this.props.lensType}`
          )
        );
      }
      else {
        rxOptions.push(
          neutralizedOption,
          this.renderButtonListItem('Glasses',
            () => this.setState({rxType: 'prior_glasses_rx'}),
            'rx-option-glasses'
          ),
          this.renderButtonListItem('Contacts',
            () => this.setState({rxType: 'prior_contacts_rx'}),
            'rx-option-contacts'
          )
        );
      }

      return DOM.div(
        null,
        DOM.div({className: 'rx-check-step__message ta-l'}, rxTypeLabel),
        rxNote,
        rxOptions,
        this.renderButtonListItem('Send Later', handleClickSendLater)
      );
    }
    else {
      return [React.createElement(FormLabel, {className: 'u-field-label', label: 'prescription type', key: 'label'}),
        DOM.div({
          className: 'rx-check-prior-rx--type tt-c edit',
          key: 'edit',
          onClick: () => this.setState({rxType: null})},
          _.get(this.props.rxTypes, `${this.state.rxType}.label`))];
    }
  }

  renderMonth() {
    return React.createElement(FormSelect, {
      className: 'form-select d-ib ta-l mr-20--600 w-45p--600',
      label: 'month',
      errors: _.get(this.state, 'validation.month.errors', []),
      options: MONTHS,
      value:  this.state.month,
      onChange: (ev) => this.handleChangeMonth(ev.target.value)
    });
  }

  renderYear() {
    return React.createElement(FormSelect, {
      className: 'form-select d-ib ta-l w-45p--600',
      errors: _.get(this.state, 'validation.year.errors', []),
      label: 'year',
      options: years,
      value:  this.state.year,
      onChange: (ev) => this.handleChangeYear(ev.target.value)
    });
  }

  renderUnknown() {
    return DOM.label(null,
      DOM.input({
        checked: this.state.unknownIssueDate && !(this.state.month || this.state.year),
        onChange: (ev) => this.handleChangeUnknown(ev.target.checked),
        type: 'checkbox'}), 'Unknown Issue Date');
  }

  renderIssueDate() {
    if (this.props.isEditing) {
      return DOM.div({className: 'rx-check-prior-rx-issued'},
        DOM.div({className: 'rx-check-prior-rx-issued--header'}, 'Estimated Issue Date'),
        this.renderMonth(),
        this.renderYear(),
        DOM.div({className: 'rx-check-prior-rx-issued--date-unknown'}, this.renderUnknown()));
    }
    else {
      const issueDate = this.state.unknownIssueDate ? 'unknown' : `${this.state.month}/${this.state.year}`;
      DOM.div({className: 'rx-check-prior-rx-issued--header'},
        `Estimated Issue Date: ${issueDate}`);
    }
  }

  renderValidateRx() {
    const valid = _.get(this.state, 'validation.rx.valid', true);
    if (!valid) {
      const { errors, warnings } = _.get(this.state, 'validation.rx');
      return [errors.map((err, i) => DOM.div({className: 'u-field-error', key: i}, err)),
        warnings.map((warn, i) => DOM.div({className: 'u-field-warning', key: i}, warn))];
    }
  }

  renderRxTable() {
    return React.createElement(PrescriptionTable, {
      rx: this.state.rx,
      handleChangeRx: (rx) => this.setState({rx: rx}),
      isEditing: this.props.isEditing
    });
  }

  renderLensType() {
    if (this.props.lensType) {
      return DOM.div({className: 'rx-check-prior-rx--lens-type'},
        `${this.props.lensType} prescription:`
      );
    }
    else {
      return null;
    }
  }

  renderNotes() {
    const notes = _.get(this.state, 'rx.notes');
    if (this.state.isEditing) {
      return DOM.div({className: 'mb-20'},
        React.createElement(FormLabel, {className: 'u-field-label', label: 'notes'},
          DOM.textarea({
            className: 'u-field-input--textarea',
            value: notes,
            onChange: (ev) => this.setState({rx: _.assign(this.state.rx, {notes: ev.target.value})})
          })));
    }
    else {
      return DOM.div(null, notes);
    }
  }

  renderRxForm() {
    return DOM.form(
      {onSubmit: this.handleSubmit.bind(this)},
      this.renderRxType(),
      this.renderIssueDate(),
      this.renderValidateRx(),
      this.renderRxTable(),
      this.renderNotes(),
      this.renderSave()
    );
  }

  renderDetails() {
    if (this.props.isEditing) {
      if (_.isNil(this.state.rxType)) {
        return this.renderRxType();
      }
      else {
        return this.renderRxForm();
      }
    }
    else {
      return [this.renderIssueDate(),
        this.renderLensType(),
        this.renderRxTable(),
        this.renderNotes()];
    }
  }

  render() {
    return DOM.div(null, this.renderDetails());
  }
}
