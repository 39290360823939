import * as _ from "lodash";
import { ErrorActions, Survey } from "./actions";
import moment from "moment-timezone";
// import * as fetch from 'isomorphic-fetch';

interface Rx {
  sph: number;
  cyl: number;
  axis: number;
}

interface BinocularRx {
  od: Rx;
  os: Rx;
}

interface Vector {
  M0: number;
  J0: number;
  J45: number;
}

export enum HttpMethod {
  GET,
  PATCH,
  PUT,
  POST,
}

const apiHost = process.env.API_HOST || "";
const localTimezone = moment.tz.guess();

export const formatDateTime = (dateTime: moment.MomentInput): string =>
  toLocalTime(dateTime).format("l LT");

// FIXME: Go back and clean up DB to standardise these colours in an Enum or something
export const colorHexNameMapping = {
  "#f00": "Red",
  "#0f0": "Green",
  "#00f": "Blue",
  "#00c800": "Green",
  "#00af00": "Green",
  "#008c00": "Green",
};

export const angularDifference = function(a, b, symmetry = null) {
  if (symmetry == null) {
    symmetry = 360;
  }
  let diff = symmetry / 2 - Math.abs(Math.abs(a - b) - symmetry / 2);
  return Math.min(Math.abs(diff), symmetry - Math.abs(diff));
};

export const getSLATime = (
  submitted_time: moment.MomentInput,
  closed_time: moment.MomentInput
): moment.Duration => {
  if (submitted_time) {
    const later = closed_time ? moment.utc(closed_time) : moment.utc();
    return moment.duration(later.diff(moment.utc(submitted_time)));
  }
  return null;
};

export const getOpenTime = (time: moment.MomentInput): moment.Duration =>
  moment.duration(
    moment.utc().diff(moment.utc(time))
  );

const toLocalTime = (dateTime: moment.MomentInput): moment.Moment =>
  moment.tz(dateTime, "UTC").tz(localTimezone);

const powerVector = ({
  sph,
  cyl,
  axis,
}: {
  sph: number;
  cyl: number;
  axis: number;
}): Vector => ({
  M0: sph + cyl / 2,
  J0: (-cyl / 2) * Math.cos(2 * axis),
  J45: (-cyl / 2) * Math.sin(2 * axis),
});

const vectorDifference = (a: Vector, b: Vector): Vector => ({
  M0: a.M0 - b.M0,
  J0: a.J0 - b.J0,
  J45: a.J45 - b.J45,
});

const fromPowerVector = ({ M0, J0, J45 }: Vector): Rx => ({
  sph: M0 + Math.sqrt(Math.pow(J0, 2) + Math.pow(J45, 2)),
  cyl: -2 * Math.sqrt(Math.pow(J0, 2) + Math.pow(J45, 2)),
  axis: (1 / 2) * Math.atan2(J45, J0),
});

const mag = (x: number, y: number): number =>
  Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));

export const validateRx = (rx: BinocularRx): boolean =>
  rx && rx.od && rx.os && rx.od.sph !== undefined && rx.os.sph !== undefined; // TODO actual validation

const rxDifferenceByCyl = (a: Rx, b: Rx): Rx => {
  const sph = a.sph - b.sph;
  const ax = a.cyl * Math.cos(a.axis);
  const ay = a.cyl * Math.sin(a.axis);
  const bx = b.cyl * Math.cos(b.axis);
  const by_ = b.cyl * Math.sin(b.axis);
  const x = ax - bx;
  const y = ay - by_;
  let cyl = mag(x, y);
  let axis = Math.atan2(y, x);
  if (axis < 0) {
    axis += Math.PI;
    cyl = -cyl;
  }
  return { sph, cyl, axis };
};

const obDeg2Rad = (a: Rx): Rx =>
  _.assign({}, a, { axis: (a.axis * Math.PI) / 180 });
const obRad2Deg = (a: Rx): Rx =>
  _.assign({}, a, { axis: (a.axis * 180) / Math.PI });

export const rxDifference = (a: Rx, b: Rx): Rx =>
  obRad2Deg(rxDifferenceByCyl(obDeg2Rad(a), obDeg2Rad(b)));

export const rxSum = (a: Rx, b: Rx): Rx => {
  const negB = { sph: -b.sph, cyl: -b.cyl, axis: b.axis };
  return rxDifference(a, negB);
};

const returnPermittedResponse = (
  dispatch: Function,
  response: Response
): Response | Function | Promise<string> => {
  if (response.ok) {
    return response;
  } else if (response.status === 403) {
    return dispatch({ type: "FORBIDDEN" });
  } else {
    return Promise.reject(response);
  }
};

const getResponseJson = (response: Response): Promise<any> =>
  typeof response.json === "function" ? response.json() : undefined;

const dispatchError = (dispatch: Function, response: Response) => {
  dispatch(ErrorActions.error(response.statusText));
  setTimeout(() => dispatch(ErrorActions.clear()), 5000);

  return dispatch({
    type: "RESPONSE_ERROR",
    response,
  });
};

export const fetchJson = (
  uri: string,
  request: RequestInit,
  dispatch: Function
) =>
  fetch(uri, request)
    .then(_.partial(returnPermittedResponse, dispatch))
    .then(getResponseJson)
    .catch(_.partial(dispatchError, dispatch));

export const fetchJsonWithAuth = (
  uri: string,
  method: string,
  body: any,
  dispatch: Function,
  getState: Function
): Promise<Response> => {
  const request = buildAuthRequest(getState, body ? { body: body, method: method} : {});
  return fetchJson(uri, request, dispatch);
};

export const simpleAuthRequest = (getState: Function): RequestInit => {
  const authorization = getState().authContext ? `bearer ${getState().authContext.user.access_token}` : null;
  if (authorization) {
    return { headers: { Authorization: authorization } };
  } else {
    return { credentials: "include" };
  }
};
export const buildAuthRequest = (getState: Function, params: any): RequestInit => {
  const authorization = getState().authContext ? `bearer ${getState().authContext.user.access_token}` : null;
  const { body, contentType, method } = params;
  const request = {
    body: JSON.stringify(body),
    method: method
  };
  if (authorization) {
    request["headers"] = { "Content-Type": contentType, Authorization: authorization };
  } else {
    request["headers"] = { "Content-Type": contentType };
  }
  return request;
};

const captureArgs = fn => (...args) =>
  _.spread(_.partial)([fn].concat(args.slice(0, fn.length - 1)));

export const bindDispatchersToArgs = dispatchFunctions =>
  Object.keys(dispatchFunctions).reduce((obj, key) => {
    obj[key] = captureArgs(dispatchFunctions[key]);
    return obj;
  }, {});

export const errorFunction = eventSource => (
  messageOrEvent,
  source,
  lineno,
  colno,
  error
) => {
  const event = {
    message: "JavaScript error",
    args: { messageOrEvent, source, lineno, colno, error },
    event_source: eventSource,
  };

  event["timestamp"] = Date.now();
  const xhrRequest = new XMLHttpRequest();
  const url = `${apiHost}/log`;
  xhrRequest.open("POST", url, true);
  return xhrRequest.send(JSON.stringify(event));
};

export function objectToQueryString(obj) {
  return _
    .reduce(
      obj,
      (result, value, key) =>
        !_.isNull(value) && !_.isUndefined(value)
          ? (result += key + "=" + value + "&")
          : result,
      ""
    )
    .slice(0, -1);
}

export function parseQueryString(query: string): { [key: string]: string } {
  if (query.substr(0, 1) == "?") {
    query = query.substr(1);
  }
  return query
    .split("&")
    .map(s => s.split("="))
    .reduce((acc, val) => {
      const decoded = val.map(decodeURIComponent);
      acc[decoded[0]] = decoded[1];
      return acc;
    }, {});
}

export function hexToBase31(val: string, length: string): string {
  // Take a hex string and crudely hash it into a string of unambiguous
  // characters of the given length.
  let code = "ABCDEFGHJKMNPQRSTUVWXYZ23456789";
  //Fill the array with zeros.
  var result = Array.apply(null, new Array(length)).map(() => 0);
  for (var i = 0; i < val.length; i++) {
    result[i % result.length] += parseInt(val.charAt(i), 16);
  }
  return result.map(c => code[c % 32]).join("");
}

export function extractAgeFromSurvey(
  survey: Survey | object | null
): String | null {
  return _.get(
    survey,
    "age.response",
    _.get(survey, "age") || _.get(survey, "ios_survey.age[0]")
  ) as String | null;
}
