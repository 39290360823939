import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import DOM from 'react-dom-factories';
import FormSelect from './form/FormSelect'
import Markdown from './TrustedUnsafeMarkdown';
import { acuityDenominators, acuityLookup, correctionTypes, validateAcuity, validateCorrection } from '../validation/acuity';
import { aggregate } from '../validation/validation';
import AcuityNote from '../../copy/acuity.md';

const NOT_SELECTED = {label: '--', value: null};
const FIELDS = ['acuity_od', 'acuity_os', 'correction'];

const acuityScores = _.concat([NOT_SELECTED], acuityDenominators.map(denom => {
  return {label: `20 / ${denom}`, value: denom};
}));
const correctionOptions = _.concat([NOT_SELECTED], correctionTypes.map(t => {
  return {label: t.replace('_', ' '), value: t};
}));


export default class EditableAcuity extends React.Component {
  static get propTypes() {
    return {
      uid: PropTypes.string,
      acuity_od: PropTypes.string,
      acuity_os: PropTypes.string,
      correction: PropTypes.string,
      correction_whitelist: PropTypes.string,
      isEditing: PropTypes.bool,
      handleClickSubmit: PropTypes.func,
      handleClickBack: PropTypes.func,
      handleClickNext: PropTypes.func,
      priorRxType: PropTypes.string,
      rxTypes: PropTypes.object.isRequired,
      submitText: PropTypes.string
    }
  }

  componentWillMount() {
    this.setStateFromProps();
  }

  setStateFromProps() {
    this.setState({
      isEditing: _.get(this.props, 'isEditing', false),
      acuity_od: _.get(this.props, 'acuity_od'),
      acuity_os: _.get(this.props, 'acuity_os'),
      correction: _.get(this.props, 'correction', _.get(acuityLookup, this.props.priorRxType))
    });
  }

  handleClickEdit(event) {
    event.preventDefault();
    this.setState({isEditing: true});
    this.props.handleClickEdit();
  }

  validateFieldAndSet(field, value) {
    var validation = _.get(this.state, 'validation', {});
    validation[field] = this.validateField(field, value);
    this.setState({validation: validation});
    return validation[field];
  }

  handleChange(field, event) {
    const value = event.target.value;
    const fieldValidation = this.validateFieldAndSet(field, value);
    let validation = {[field]: fieldValidation};
    const allValidation = FIELDS.map((f) => {
      let fieldValidation;
      if (f === field) {
        fieldValidation = this.validateFieldAndSet(field, value);
      } else {
        fieldValidation = this.validateField(f);
        validation[f] = fieldValidation;
      }
      return fieldValidation;
    });

    this.setState(_.assign({[field]: value},
      {showWarnings: aggregate(allValidation).warnings.length > 0, validation: validation}));
  }

  handleSubmit(event) {
    event.preventDefault();
    let data = _.pick(this.state, FIELDS);
    if (this.props.uid) {
      data['uid'] = this.props.uid;
    }
    const {correction, acuity_os, acuity_od} = this.validateFields();
    const validation = aggregate([correction, acuity_os, acuity_od]);
    if (validation.valid) {
      this.props.handleSubmit(data).then(this.props.handleClickNext());
    } else if (validation.errors.length === 0) {
      if (_.get(this.state, 'showWarnings', false)) {
        this.props.handleSubmit(data).then(this.props.handleClickNext());
      } else {
        this.setState({showWarnings: true});
      }
    }
  }

  validateField(field, value) {
    value = value === undefined ? _.get(this.state, field) : value;
    switch (field) {
      case 'correction':
        return validateCorrection({
          correction: value,
          priorRxCorrection: this.props.priorRxType,
          rxTypes: this.props.rxTypes
        });
        break;
      default:
        return validateAcuity(value);
    }
  }

  validateFields() {
    return {
      correction: this.validateField('correction'),
      acuity_os: this.validateField('acuity_os'),
      acuity_od: this.validateField('acuity_od')
    };
  }

  validate() {
    const validation = this.validateFields();
    this.setState({validation: validation, showWarnings: validation.warnings.length > 0});
  }

  correctionTypeLabel(typ) {
    if (typ === undefined) {
      return '';
    }
    const citem = _.find(correctionOptions, (t) => {return typ === t.value});
    if (citem === undefined) {
      return '';
    }
    return citem.label;
  }

  renderCorrectionType() {
    if (this.state.isEditing) {
      return React.createElement(FormSelect, {
        label: 'correction type',
        options: correctionOptions,
        errors: _.get(this.state, 'validation.correction.errors', []),
        warnings: _.get(this.state, 'validation.correction.warnings', []),
        defaultValue:  _.get(this.state, 'correction'),
        onChange: this.handleChange.bind(this, 'correction')
      });
    } else {
      const label = this.correctionTypeLabel(_.get(this.props, 'correction'));
      return DOM.div(null, label);
    }
  }

  renderAcuityScore(eye) {
    let acuity_prop = `acuity_${eye}`;
    if (this.state.isEditing) {
      let acuity = _.get(this.state, acuity_prop);
      return React.createElement(FormSelect, {
        label: eye,
        errors: _.get(this.state, `validation.${acuity_prop}.errors`, []),
        warnings: _.get(this.state, `validation.${acuity_prop}.warnings`, []),
        options: acuityScores,
        defaultValue: acuity,
        onChange: this.handleChange.bind(this, acuity_prop)
        });
    } else {
      let acuity = _.get(this.props, acuity_prop);
      if (_.isNil(acuity)) {
        return  DOM.div({className: 'sc-a'}, `Acuity (${eye.toUpperCase()}):`);
      }
      return DOM.div({className: 'sc-a'}, `Acuity (${eye.toUpperCase()}): 20 / ${acuity}`);
    }
  }

  readyToSubmit() {
    const {correction, acuity_od, acuity_os} = this.validateFields();
    const validation = aggregate([correction, acuity_od, acuity_os]);
    return validation.errors.length === 0;
  }

  renderSave() {
    const message = _.get(this.state, 'showWarnings', false) ? 'Submit with warnings' : 'Save and continue';
    return DOM.button({
      className: 'button',
      key: 'submit-acuity',
      type: 'submit',
      disabled: !this.readyToSubmit()
    }, message);
  }

  renderShowChart() {
    return DOM.button(
      {
        className: 'button secondary',
        onClick: () => this.setState({showAcuityChart: true}),
      },
      'Show eye chart'
    );
  }

  renderForm() {
    if (this.state.isEditing) {
      if (this.state.showAcuityChart) {
        return DOM.div({className: 'acuity-overlay'},
          DOM.img({src: 'static/images/eye-chart.png'}),
          DOM.button(
            {
              className: 'button secondary overlay',
              onClick: () => this.setState({showAcuityChart: false}),
            },
            'Return to Prescription Check'
          )
        );
      } else {
        return DOM.form({onSubmit: this.handleSubmit.bind(this)},
          DOM.div({className: 'mb-10'},
            React.createElement(
              Markdown,
              {unsafeRawMarkdown: AcuityNote}
            ),
            this.renderCorrectionType(),
            this.renderAcuityScore('od'),
            this.renderAcuityScore('os'),
            this.renderSave(),
            this.renderShowChart()));
      }
    } else {
      return DOM.div(null,
        this.renderCorrectionType(),
        this.renderAcuityScore('od'),
        this.renderAcuityScore('os'));
    }
  }

  render() {
    return DOM.div(
      {className: 'rx-check-step__detail'},
      this.renderForm()
    );
  }
}
