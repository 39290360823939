import PropTypes from 'prop-types';
import React from 'react';
import DOM from 'react-dom-factories';
import RxCheckHeader from './RxCheckHeader';

export default class RxCheckLayout extends React.Component {
  static get propTypes() {
    return {
      cssModifier: PropTypes.string,
      user: PropTypes.any,
      handleClickHeader: PropTypes.func
    }
  }
  static get defaultProps() {
    return {
      cssModifier: '',
      user: {}
    }
  }

  render() {
    return DOM.main(null,
      React.createElement(RxCheckHeader, this.props),
      this.props.children
    )
  }
}
