import PropTypes from 'prop-types';
import React from 'react';
import DOM from 'react-dom-factories';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment-timezone';
import * as locations from '../locations';
import { validateLocation } from '../validation/location';
import { fetchPOELocation, fetchPrescriptionRequests } from '../functions/apiFunctions';
import { formatDateTime, getOpenTime, bindDispatchersToArgs } from '../util';
import FormSelect from '../presentational/form/FormSelect';
import Loading from '../presentational/Loading';


let locationCatchall = "XALL"; // TODO get locations from SF API, stop with this foolishness

class SelectPatientContainer extends React.Component {
  static get propTypes() {
    return {
      prescriptionRequests: PropTypes.any,
      fetchPrescriptionRequestsIfNeeded: PropTypes.func,
      isLoading: PropTypes.bool,
      onSelect: PropTypes.func.isRequired,
      userLocation: PropTypes.string
    };
  }

  componentDidMount() {
    this.props.fetchPrescriptionRequests({source: 'kiosk', status: 'Incomplete'});
  }

  preparePrescriptionRequests() {
    let prescriptionRequests = _.filter(
      this.props.prescriptionRequests,
      (request) => {
        if (!request || request.assignee != null) {
          return false;
        }
        // if the request has a location, allow it if we're using the catchall to filter...
        if (this.props.userLocation !== locationCatchall) {
          // ...otherwise only if we're looking at the correct location
          if (request.location !== this.props.userLocation){
            return false;
          }
        }
        let time = getOpenTime(request.time);
        return time ? (time < moment.duration(24, 'hours')) : true;
      },
      this
    );
    prescriptionRequests = _.orderBy(prescriptionRequests, v => moment.utc(v.time), 'desc');
    return prescriptionRequests;
  }

  renderLocationIndicator() {
    if (locations[this.props.userLocation] && fetchPOELocation()) {
      return DOM.div({className: 'mb-20'},
        DOM.div({className: 'u-field-label'}, `Your Location`),
        DOM.div({}, locations[this.props.userLocation].label)
      );
    } else {
      const locationIds = ["--"].concat(Object.keys(locations.default));
      const locs = _.sortBy(locationIds.map((id, i) => {
        return {
          label: _.get(locations, `${id}.label`, '--'),
          value: id,
          key: i
        };
      }), 'label');
      return React.createElement(FormSelect, {
        errors: [validateLocation(this.props.userLocation).errors],
        className: 'form-select d-ib ta-l w-50p--600',
        label: 'set store userLocation',
        key: 'userLocation-select',
        options: locs,
        value:  this.props.userLocation || '--',
        onChange: (evt) => this.props.onChangeLocation(evt.target.value == '--' ? null : evt.target.value)
      });
    }
  }

  renderList() {
    if (!validateLocation(this.props.userLocation).valid) {
      return '';
    } else if (this.props.isLoading) {
      return React.createElement(Loading);
    } else {
      return this.preparePrescriptionRequests().map(prescriptionRequest => {
        let {uid, patient_uid, first_name, last_name, email, time} = prescriptionRequest;

        let name;
        if (first_name && last_name) {
          name = `${first_name} ${last_name}`;
        } else {
          name = uid;
        }
        return DOM.button({
            className: 'u-button-list-item -is-multiline',
            key: `${uid}-${patient_uid}`,
            onClick: this.props.onSelect.bind(this, patient_uid, uid)
          },
          DOM.span({className: 'd-b'}, name),
          DOM.span({className: 'd-b'}, email),
          DOM.span({className: 'd-b'}, `${formatDateTime(time)}`));
      });
    }
  }
  render() {
    return DOM.section({className: 'p-10 ta-c'},
      this.renderLocationIndicator(),
      this.renderList());
  }
}

function mapStateToProps(state) {
  return {
    prescriptionRequests: _.get(state, 'prescriptionRequests.data', []),
    isLoading: _.get(state, 'prescriptionRequests.isFetching')
  };
}

const mapDispatchToProps = bindDispatchersToArgs({
    fetchPrescriptionRequests
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectPatientContainer);
