import { combineReducers } from "redux";
import * as _ from "lodash";
import {
  ActionTypes,
  AuthContext,
  Patient,
  PatientEntries,
  User,
  PrescriptionRequest,
  PrescriptionRequests
} from "../actions";
import { fetchPOELocation } from "../functions/apiFunctions";

// These are the only types relevant to this workflow
const PATIENT_ENTRY_TYPES = [
  "pd",
  "prior_rx",
  "acuity",
  "device_reading",
  "kiosk_survey"
];

const apiHost = process.env.API_HOST || "";
const FORBIDDEN_LOCATION = '/';
const USER_LOCATION = "warbyStoreLocation";

interface Fetchable {
  isFetching: boolean;
}
interface ErrorType {
  errors?: any;
}
const NOT_FETCHING: Fetchable = { isFetching: false };
const FETCHING: Fetchable = { isFetching: true };

type FetchableAuthContext = AuthContext & Fetchable | Fetchable;
type FetchableUser = User & Fetchable | Fetchable;
type FetchablePatient = Patient & Fetchable | Fetchable;
type FetchablePatientEntries = PatientEntries & Fetchable | Fetchable;
type FetchablePrescriptionRequest = Fetchable & PrescriptionRequest | Fetchable;
type FetchablePrescriptionRequests =
  | Fetchable & PrescriptionRequests
  | Fetchable;

export function user(state: FetchableUser, action): FetchableUser {
  switch (action.type) {
    case ActionTypes.CLEAR_USER:
      return _.assign({}, NOT_FETCHING);
    case ActionTypes.REQUEST_USER:
      return _.assign({}, state, FETCHING);
    case ActionTypes.RECEIVE_USER:
      return _.assign({}, NOT_FETCHING, action.user);
    default:
      if (state) {
        return state;
      } else {
        return _.assign({}, NOT_FETCHING);
      }
  }
}

export function authContext(state: FetchableAuthContext, action): FetchableAuthContext {
  switch (action.type) {
    case ActionTypes.CLEAR_AUTH_CONTEXT:
      return _.assign({}, NOT_FETCHING);
    case ActionTypes.REQUEST_AUTH_CONTEXT:
      return _.assign({}, state, FETCHING);
    case ActionTypes.RECEIVE_AUTH_CONTEXT:
      return _.assign({}, NOT_FETCHING, action.authContext);
    default:
      if (state) {
        return state;
      } else {
        return _.assign({}, NOT_FETCHING);
      }
  }
}

export function error(errors: ErrorType, action) {
  switch (action.type) {
    case "FORBIDDEN":
    case ActionTypes.FORBIDDEN:
      location.href = FORBIDDEN_LOCATION;
      return {};
    case ActionTypes.ERROR:
      return { ...errors, error: action.error };
    case ActionTypes.CLEAR_ERRORS:
      return {};
    default:
      return errors ? errors : {};
  }
}

function _getName(patient: Patient) {
  if (patient.first_name && patient.last_name) {
    return `${patient.first_name} ${patient.last_name}`;
  }
}

export function patient(patient: FetchablePatient, action): FetchablePatient {
  switch (action.type) {
    case ActionTypes.RECEIVE_PATIENT:
      const name = _getName(action.patient);
      let start = {};
      if (name != null) {
        start["name"] = name;
      }
      return _.assign(start, _.omit(action.patient, ["blob"]), NOT_FETCHING);
    case ActionTypes.CLEAR_PATIENT:
      return _.assign({}, NOT_FETCHING);
    default:
      if (patient) {
        return patient;
      } else {
        return _.assign({}, NOT_FETCHING);
      }
  }
}

function transformPatientEntries(entries) {
  return entries
    .filter(e => _.includes(PATIENT_ENTRY_TYPES, e.type))
    .map(flattenPatientEntry);
}

function flattenPatientEntry(entry) {
  switch (entry.type) {
    case "pd":
    case "kiosk_survey":
      return _.assign(
        {},
        _.omit(entry, "blob"),
        _.get(entry, `blob.${entry.type}`)
      );
    default:
      return _.assign({}, _.omit(entry, "blob"), _.get(entry, "blob"));
  }
}

export function patientEntries(
  patientEntries: FetchablePatientEntries,
  action
): FetchablePatientEntries {
  switch (action.type) {
    case ActionTypes.RECEIVE_PATIENT_ENTRIES:
      return _.assign(stateFromEntries(action.entries), NOT_FETCHING);
    case ActionTypes.RECEIVE_PATIENT_ENTRY:
      const entry = flattenPatientEntry(action.patientEntry);
      const entryType = _.get(entry, "type");
      let entries = _.assign({}, patientEntries);
      const existingEntriesOfType = _.get(entries, entryType, []);

      // Add the new entry to the array at entries[entryType] iff its uid
      // is either missing or unique to that array.
      const entryUid = _.get(entry, "uid");
      if (!entryUid || !_.some(existingEntriesOfType, { uid: entryUid })) {
        _.set(entries, entryType, existingEntriesOfType.concat(entry));
      }

      return _.assign(entries, NOT_FETCHING);
    case ActionTypes.CLEAR_PATIENT_ENTRIES:
      return _.assign({}, NOT_FETCHING);
    default:
      if (patientEntries) {
        return patientEntries;
      } else {
        return _.assign({}, NOT_FETCHING);
      }
  }
}

function stateFromEntries(entries): PatientEntries {
  /*
  Normalize entries into a structure something like:
  {
    prior_rx: [array of prior_rx patient entry objects],
    acuity: [array of acuity patient entry objects],
    device_reading: [array of device_reading patient entry objects],
    ...
  }

  prior_rx is the only one for which we actually need to show multiple rxes
  (one for contacts and one for glasses). For the rest, in actual usage we
  just grab the first entry in the array.
  */
  const transformedEntries = transformPatientEntries(entries);
  const sortedEntries = _.orderBy(transformedEntries, "time", "desc");
  return _.groupBy(sortedEntries, "type");
}

export function prescriptionRequests(
  state: FetchablePrescriptionRequests,
  action
): FetchablePrescriptionRequests {
  switch (action.type) {
    case ActionTypes.REQUEST_PRESCRIPTION_REQUESTS:
      return _.assign({}, state, FETCHING);
    case ActionTypes.RECEIVE_PRESCRIPTION_REQUESTS:
      return _.assign({}, state, NOT_FETCHING, {
        data: action.prescriptionRequests
      });
    default:
      if (state) {
        return state;
      } else {
        return _.assign({}, NOT_FETCHING);
      }
  }
}

export function prescriptionRequest(
  request: FetchablePrescriptionRequest,
  action
): FetchablePrescriptionRequest {
  switch (action.type) {
    case ActionTypes.REQUEST_PRESCRIPTION_REQUEST:
      // NB: This intentionally does not keep uploadedRxImageData between fetches
      return _.assign({}, FETCHING);
    case ActionTypes.RECEIVE_PRESCRIPTION_REQUEST:
      return _.assign({}, NOT_FETCHING, action.prescriptionRequest);
    case ActionTypes.REQUEST_UPLOADED_RX:
      return _.assign({}, request, FETCHING);
    case ActionTypes.RECEIVE_UPLOADED_RX:
      return _.assign({}, request, NOT_FETCHING, {
        uploadedRxImageData: action.uploadedRxImageData
      });
    default:
      if (request) {
        return request;
      } else {
        return _.assign({}, NOT_FETCHING);
      }
  }
}

export function locationReducer(userLocation: string, action): string {
  switch (action.type) {
    case ActionTypes.RECEIVE_LOCATION:
      if (action.userLocation === null) {
        localStorage.removeItem(USER_LOCATION);
      } else {
        localStorage.setItem(USER_LOCATION, action.userLocation);
      }
      return action.userLocation;
    default:
      const poeLocation = fetchPOELocation();
      if (poeLocation) {
        return poeLocation;
      } else if (userLocation) {
        return userLocation;
      } else {
        return localStorage.getItem(USER_LOCATION);
      }
  }
}

export default combineReducers({
  error: error,
  authContext: authContext,
  user: user,
  prescriptionRequests: prescriptionRequests,
  prescriptionRequest: prescriptionRequest,
  patient: patient,
  entries: patientEntries,
  userLocation: locationReducer
});
