import PropTypes from 'prop-types';
import React from 'react';
import DOM from 'react-dom-factories';

import FormLabel from './FormLabel';

function option(attrs, classes) {
  return DOM.option({
    className: classes,
    key: attrs.value,
    value: attrs.value
  }, attrs.label);
}

export default class FormSelect extends React.Component {
  static get propTypes() {
    return {
      className: PropTypes.string,
      classes: PropTypes.any,
      label: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.string,
      errors: PropTypes.array,
      warnings: PropTypes.array,
      defaultValue: PropTypes.string,
      onChange: PropTypes.func.isRequired,
      options: PropTypes.any.isRequired
    };
  }
  static get defaultProps() {
    return {
      classes: {
        select: 'u-field-input',
        wrapper: 'u-field-wrapper--select',
        label: 'u-field-label',
        error: 'u-field-error',
        warning: 'u-field-warning'
      },
      className: 'form-select',
      label: '',
      name: '',
      errors: [],
      warnings: []
    }
  }
  renderLabel() {
    if (this.props.label !== undefined) {
      return DOM.div({className: this.props.classes.label},
        React.createElement(FormLabel, {label: this.props.label}));
    }
  }
  renderErrors() {
    if (this.props.errors !== undefined) {
      return this.props.errors.map((error, i) =>
        DOM.div({className: this.props.classes.error, key: i}, error));
    }
  }
  renderWarnings() {
    if (this.props.warnings !== undefined) {
      return this.props.warnings.map((warning, i) =>
        DOM.div({className: this.props.classes.warning, key: i}, warning));
    }
  }
  render() {
    return DOM.section({className: this.props.className, key: this.props.name},
      this.renderLabel(),
      this.renderErrors(),
      this.renderWarnings(),
      DOM.div({className: this.props.classes.wrapper},
        DOM.select({
          className: this.props.classes.select,
          name: this.props.name,
          key: this.props.value || this.props.defaultValue,
          onChange: this.props.onChange,
          value: this.props.value || this.props.defaultValue
        }, this.props.options.map(option, this.props.classes.options)))
    );
  }
}
