import React from "react";
import { connect } from "react-redux";
import { bindDispatchersToArgs } from "../util";
import * as fns from "../functions/apiFunctions";
import { AuthRxCheckPatientContainer } from "./AuthRxCheckPatientContainer";
import { RxCheckPatientContainer } from "./RxCheckPatientContainer";
import { withAuthContext } from './AuthProvider.js';
import { withRouter } from "react-router-dom";

function mapStateToProps(state) {
  return {
    authContext: state.authContext,
    entries: state.entries,
    prescriptionRequest: state.prescriptionRequest || {},
    isLoading: state.isLoading,
    patient: state.patient,
    user: state.user,
    userLocation: state.userLocation
  };
}

let mapDispatchToProps = bindDispatchersToArgs({
  fetchPatient: fns.fetchPatient,
  clearPatient: fns.clearPatient,
  updatePatient: fns.updatePatient,
  fetchPatientEntries: fns.fetchPatientEntries,
  fetchPrescriptionRequest: fns.fetchPrescriptionRequest,
  getUser: fns.getUser,
  updateNotes: fns.updateNotes,
  signoffPrescriptionRequest: fns.signoffPrescriptionRequest,
  savePatientEntry: fns.savePatientEntry,
  updatePatientEntry: fns.updatePatientEntry,
  updateRxDate: fns.updateRxDate,
  getLocation: fns.getLocation,
  updateLocation: fns.updateLocation,
});

export const ReduxRxCheckPatientContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RxCheckPatientContainer);

export const AuthReduxRouterRxCheckPatientContainer = connect(
  mapStateToProps,
  bindDispatchersToArgs({
    fetchPatient: fns.fetchPatient,
    clearPatient: fns.clearPatient,
    updatePatient: fns.updatePatient,
    fetchPatientEntries: fns.fetchPatientEntries,
    fetchPrescriptionRequest: fns.fetchPrescriptionRequest,
    getUser: fns.getUser,
    setAuthContext: fns.setAuthContext,
    updateNotes: fns.updateNotes,
    signoffPrescriptionRequest: fns.signoffPrescriptionRequest,
    savePatientEntry: fns.savePatientEntry,
    updatePatientEntry: fns.updatePatientEntry,
    updateRxDate: fns.updateRxDate,
    getLocation: fns.getLocation,
    updateLocation: fns.updateLocation,
  })
)(withRouter(withAuthContext(AuthRxCheckPatientContainer)));
