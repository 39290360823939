import _ from 'lodash';
import React from 'react';
import Marked from 'marked';
import PropTypes from 'prop-types';

export default class Markdown extends React.Component {
  // NOTE: this component is designed for trusted markdown (loaded at build) only.
  // Should you ever want to use this for markdown coming from ANYWHERE ELSE,
  // make sure to add content santizing!
  static get propTypes() {
    return {
      unsafeRawMarkdown: PropTypes.string,
      styles: PropTypes.string
    }
  }

  static get defaultProps() {
    return {
      styles: 'ta-c px-20 pb-20 m-0a'
    }
  }

  renderMarkdown() {
    return {
      __html: Marked(this.props.unsafeRawMarkdown)
    }
  }

  render() {
    return (_.isEmpty(this.props.unsafeRawMarkdown) ?
      false :
      (<div className={this.props.styles} dangerouslySetInnerHTML={this.renderMarkdown()} />)
    );
  }
}
