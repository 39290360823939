import * as React from "react";
import DOM from "react-dom-factories";

export enum KeypadMode { KeypadPowerMode, KeypadAxisMode }

interface KeypadParams {
    onClick: (String) => void;
    onSubmit?: () => void;
    mode: KeypadMode;
}

function button(value, className?) {
    return DOM.button({
        key: value,
        className: `keypad-button keypad-button-${className ? className : value}`
    }, value);
}

export class KeypadComponent extends React.Component<KeypadParams, null> {
    handleClick(ev) {
        ev.preventDefault();
        if (ev.target.classList.contains("keypad-button")) {
            this.props.onClick(ev.target.innerText);
        }
    }

    handleEnter(ev) {
        ev.stopPropagation();
        ev.preventDefault();
        if (this.props.onSubmit) {
            this.props.onSubmit();
        }
    }

    render() {
        return DOM.div({onClick: this.handleClick.bind(this)},
            this.props.mode == KeypadMode.KeypadPowerMode ?
            DOM.div({className: "keypad-button-group keypad-button-group-plusminus"},
                button("-", "minus"),
                button("+", "plus"),
            ) : undefined,
            DOM.div({className: "keypad-button-group keypad-button-group-0-9"},
                button("1"),
                button("2"),
                button("3"),
                button("4"),
                button("5"),
                button("6"),
                button("7"),
                button("8"),
                button("9"),
                button("0"),
            ),
            this.props.mode == KeypadMode.KeypadPowerMode ?
                DOM.div({className: "keypad-button-group keypad-button-group-quarters"},
                    button(".25", "25"),
                    button(".50", "50"),
                    button(".75", "75"),
                    button(".00", "00")
                )
                :
                DOM.div({className: "keypad-button-group keypad-button-group-quarters"},
                    DOM.button({className: "keypad-button keypad-button-enter",
                        onClick: this.handleEnter.bind(this)}, "Enter")
                )
        )
    }
}
