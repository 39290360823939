import { connect } from 'react-redux';
import ErrorComponent from '../presentational/Error';

function mapStateToProps(state) {
  if (state.app) {
    return {error: state.app.error, forbidden: state.app.forbidden};
  } else {
    return {};
  }
}

export default connect(mapStateToProps)(ErrorComponent);