import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import EditablePriorRx from './EditablePriorRx';

export default class PriorRxStep extends React.Component {
  static get propTypes() {
    return {
      isEditing: PropTypes.bool,
      rxes: PropTypes.array
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      currentlyWearing: null,
      focusedRxType: null
    };
  }

  getRxes() {
    let rxes = {
      contacts: null,
      glasses: null,
      neutralized: null
    };
    _.forEach(this.props.rxes, function(prior_rx) {
      let {rx, uid} = prior_rx;
      if (rx.type === 'prior_contacts_rx') {
        rxes.contacts = {rx, uid};
      }
      if (rx.type === 'prior_glasses_rx') {
        rxes.glasses = {rx, uid};
      }
      if (rx.type === 'prior_neutralized_rx') {
        rxes.neutralized = {rx, uid};
      }
      if (rx.type === 'waiting_for_prior_rx' && rx.lens_type) {
        rxes[rx.lens_type] = {rx, uid}
      }
      // Break the loop if all rx types have been found
      return !_.every(rxes);
    });
    return rxes;
  }

  renderButtonListItem(label, handleClick) {
    return (
      <button className={'u-button-list-item tt-c'} onClick={handleClick} children={label} />
    );
  }

  renderCurrentlyWearing() {
    return (
      <div>
        <div className={'rx-check-step__message ta-l'}>
          What’s the customer wearing right now?
        </div>
        {this.renderButtonListItem('Glasses', () => this.setState({currentlyWearing: 'glasses'}))}
        {this.renderButtonListItem('Contacts', () => this.setState({currentlyWearing: 'contacts'}))}
        {this.renderButtonListItem('Neither', () => this.setState({currentlyWearing: 'nothing'}))}
      </div>
    );
  }

  renderRxPicker() {
    return (
      <div>
        <div className={'rx-check-step__message ta-l'}>
          Previous prescription type
        </div>
        {this.renderButtonListItem(
          'Previous glasses prescription',
          () => this.setState({focusedRxType: 'glasses'})
        )}
        {this.renderButtonListItem(
          'Previous contacts prescription',
          () => this.setState({focusedRxType: 'contacts'})
        )}
      </div>
    );
  }

  renderPriorRx(lensType, rxData, key) {
    const props = _.assign(
      _.omit(this.props, 'rxes'),
      _.pick(rxData, 'rx', 'uid'),
      {key},
      {lensType: lensType}
    );
    if (this.state.currentlyWearing === 'contacts') {
      // Override default handleClickNext in this case to move to the next substep,
      // instead of moving to the next top-level step as handleClickNext normally would.
      props.handleClickNext = () => this.setState({currentlyWearing: 'glasses'});
    }

    return React.createElement(EditablePriorRx, props);
  }

  renderReview(rxes) {
    return _.map(rxes, (rx, id) => this.renderPriorRx(id, rx, `review-rx-${id}`));
  }

  renderDetails() {
    const rxes = this.getRxes();
    console.log(rxes);
    if (this.props.isEditing) {

      // If all rx types (glasses and contacts) have been provided already,
      // this shows a menu where the user can jump back into them to edit them.
      if (_.every(rxes)) {
        if (this.state.focusedRxType === 'contacts') {
          return this.renderPriorRx('contacts', rxes.contacts);
        }
        else if (this.state.focusedRxType === 'glasses') {
          return this.renderPriorRx('glasses', rxes.glasses);
        }
        else {
          return this.renderRxPicker();
        }
      }
      else {
        if (_.isNil(this.state.currentlyWearing)) {
          return this.renderCurrentlyWearing();
        }
        else if (this.state.currentlyWearing === 'contacts') {
          // Show the "prior contacts" prescription
          return this.renderPriorRx('contacts', rxes.contacts);
        }
        else {
          // Show the "prior glasses" prescription
          return this.renderPriorRx('glasses', rxes.glasses);
        }
      }
    }
    else {
      return this.renderReview(_.pickBy(rxes));
    }
  }

  render() {
    return (
      <div className={'rx-check-step__detail'} children={this.renderDetails()} />
    );
  }
}
