import PropTypes from 'prop-types';
import React from 'react';
import DOM from 'react-dom-factories';

export default class FormLabel extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string.isRequired,
      className: PropTypes.string
    }
  }
  render() {
    return DOM.label({className: this.props.className}, this.props.label, this.props.children);
  }
}
