import _ from "lodash";
import React from "react";
import DOM from "react-dom-factories";
import { Link } from "react-router-dom";
import {
  extractAgeFromSurvey,
  hexToBase31
} from "../util";
import { rxTypes, validateRx, validateIssueDate } from "../validation/rx";
import * as fns from "../functions/apiFunctions";
import ErrorContainer from "./ErrorContainer";
import RxCheckLayout from "../presentational/layout/RxCheckLayout";
import EditableAcuity from "../presentational/EditableAcuity";
import EditableDeviceReading from "../presentational/EditableDeviceReading";
import Markdown from "../presentational/TrustedUnsafeMarkdown";
import PriorRxStep from "../presentational/PriorRxStep";
import Patient from "../presentational/Patient";
import RxCheckStepLayout from "../presentational/layout/RxCheckStepLayout";
import RxCheckStepNav from "../presentational/layout/RxCheckStepNav";
import SelectPatientContainer from "../containers/SelectPatientContainer";
import SubmissionNote from "../../copy/submission.md";

let environment = process.env.ENVIRONMENT;

function computeSubjectId(prescriptionRequestUid) {
  let salt = new Date().getTime().toString(16);
  return hexToBase31(prescriptionRequestUid + salt, 5);
}

export class AuthRxCheckPatientContainer extends React.Component {
  get steps() {
    return {
      patient: {
        step: 1,
        key: "patient",
        header: "Patient",
        renderFn: this.renderEditPatient,
        handleClickBack: () => this.setState({ selected: null }),
        handleClickEdit: () => this.setState({ selected: "patient" }),
        handleClickNext: () => this.setState({ selected: "priorRx" })
      },
      priorRx: {
        step: 2,
        key: "priorRx",
        header: "Prior Prescription",
        renderFn: this.renderEditPriorRx,
        validate: () => false,
        disabled: () => false,
        onSelect: () => this.setState({selected: 'acuity'}),
        handleSubmit: data => {
          return this.submitPatientEntryFunction("prior_rx", data.uid)(data);
        },
        handleClickBack: () => this.setState({ selected: null }),
        handleClickEdit: () => this.setState({ selected: "priorRx" }),
        handleClickNext: () => this.setState({ selected: "acuity" }),
        handleClickSkipStep: () => this.setState({ selected: "acuity" })
      },
      acuity: {
        step: 3,
        key: "acuity",
        header: "Acuity",
        renderFn: this.renderEditAcuity,
        message: "Reminder: Make sure the customer is corrected for this step!",
        validate: () => false,
        disabled: () => false,
        handleSubmit: data => {
          return this.submitPatientEntryFunction("acuity", data.uid)(data);
        },
        handleClickBack: () => this.setState({ selected: null }),
        handleClickEdit: () => this.setState({ selected: "acuity" }),
        handleClickNext: () => this.setState({ selected: "deviceReading" })
      },
      deviceReading: {
        step: 4,
        key: "deviceReading",
        header: "Device Reading",
        renderFn: this.renderEditDeviceReading,
        message:
          "Reminder: Customer must remove contacts or glasses for this step!",
        validate: () => false,
        disabled: () => false,
        handleSubmit: ({ rx, pd }) =>
          Promise.all([
            this.submitPatientEntryFunction("device_reading", rx.uid)({ rx }),
            this.submitPatientEntryFunction("pd", rx.uid)({ pd })
          ]),
        handleClickBack: () => this.setState({ selected: null }),
        handleClickEdit: () => this.setState({ selected: "deviceReading" }),
        handleClickNext: () => this.setState({ selected: "review" })
      },
      review: {
        step: 5,
        key: "review",
        header: "Review",
        renderFn: this.renderReview,
        validate: () => false,
        disabled: () => false,
        submitOnTop: true,
        handleSubmit: () => alert("submitted"),
        handleClickBack: () => this.setState({ selected: null })
      },
      confirmation: {
        step: 6,
        key: "confirmation",
        header: "Confirmation",
        renderFn: this.renderConfirmation,
        disabled: () => false,
        handleClickNext: () => this.resetForm(null)
      }
    };
  }

  componentDidMount() {
    if (this.props.auth === undefined) {
      console.log('auth undefined in AuthRxCheckPatientContainer componentDidMount - big problem')
    }
    console.log({ auth: this.props.auth,
      fn: 'AuthRxCheckPatientContainer componentDidMount' });
    let { patient_uid, prescription_request_uid } = this.props.match.params;
    this.props.setAuthContext(this.props.auth);
    this.props.getUser();
    this.props.getLocation();
    this.retrievePatientData(patient_uid, prescription_request_uid);
  }

  componentWillMount() {
    this.setState({ selected: null });
  }

  resetForm(selected) {
    this.props.clearPatient();
    this.setState({ selected: selected });
  }

  retrievePatientData(patient_uid, prescription_request_uid) {
    if (patient_uid) {
      this.props.fetchPatient(
        patient_uid,
      );
      if (prescription_request_uid) {
        this.props.fetchPatientEntries(prescription_request_uid, patient_uid);
        this.props.fetchPrescriptionRequest(
          patient_uid,
          prescription_request_uid
        );
      }
    }
  }

  nav() {
    return DOM.nav(
      { className: "my-10 p-10", key: "nav" },
      React.createElement(Link, { to: "/" }, "Prescription Requests"),
      " / ",
      _.get(this.props, "patient.name", _.get(this.props, "patient.email"))
    );
  }

  havePatient() {
    return _.get(this.props.patient, "uid") !== undefined;
  }

  isPriorRxReady() {
    // The prior rx step is ready when every rx in entries.prior_rx has a uid
    // and passes validation.
    const priorRxes = _.get(this.props, "entries.prior_rx", []);
    if (priorRxes.length) {
      return _.every(priorRxes, function(priorRx) {
        if (priorRx.uid !== undefined) {
          const rx = _.get(priorRx, "rx");
          return (
            validateIssueDate(rx).valid && validateRx(rx).errors.length === 0
          );
        } else {
          return false;
        }
      });
    }
    return false;
  }

  haveAcuity() {
    return _.get(this.props, "entries.acuity[0].uid") !== undefined;
  }

  haveDeviceReading() {
    return (
      _.get(this.props, "entries.device_reading[0].uid") !== undefined &&
      _.get(this.props, "entries.pd[0].uid") !== undefined
    );
  }

  renderSelectPatient() {
    return DOM.button(
      {
        className: `u-button-list-item ${
          this.havePatient() ? "-is-editable" : "-is-active"
        }`,
        onClick: () => this.resetForm("patient")
      },
      "Patient"
    );
  }
  renderSelectPriorRx() {
    const disabled = !this.havePatient();
    const editable = !disabled && this.isPriorRxReady();
    const active = !disabled && !editable;

    return DOM.button(
      {
        className: `u-button-list-item ${editable ? "-is-editable" : ""} ${
          active ? "-is-active" : ""
        }`,
        disabled: disabled,
        onClick: () => this.setState({ selected: "priorRx" })
      },
      "Previous prescription"
    );
  }
  renderSelectAcuity() {
    const disabled = !this.havePatient();
    const editable = !disabled && this.haveAcuity();
    const active = !disabled && !editable;

    return DOM.button(
      {
        className: `u-button-list-item ${editable ? "-is-editable" : ""} ${
          active ? "-is-active" : ""
        }`,
        disabled: disabled,
        onClick: () => this.setState({ selected: "acuity" })
      },
      "Acuity"
    );
  }
  renderSelectDeviceReading() {
    const disabled = !this.havePatient();
    const editable = !disabled && this.haveDeviceReading();
    const active = !disabled && !editable;

    return DOM.button(
      {
        className: `u-button-list-item ${editable ? "-is-editable" : ""} ${
          active ? "-is-active" : ""
        }`,
        disabled: disabled,
        onClick: () => this.setState({ selected: "deviceReading" })
      },
      "Device reading"
    );
  }
  renderSelectReview() {
    const active =
      this.havePatient() &&
      this.isPriorRxReady() &&
      this.haveAcuity() &&
      this.haveDeviceReading();

    return DOM.button(
      {
        className: `u-button-list-item ${active ? "-is-active" : ""}`,
        disabled: !active,
        onClick: () => this.setState({ selected: "review" })
      },
      "Review"
    );
  }

  renderMainMenu() {
    return DOM.div(
      { className: "rx-check-main-menu" },
      DOM.div({ className: "rx-check__title" }, "Prescription Check"),
      this.renderAdvisor(),
      DOM.div(
        { className: "u-button-list-group--ordered" },
        this.renderSelectPatient(),
        this.renderSelectPriorRx(),
        this.renderSelectAcuity(),
        this.renderSelectDeviceReading(),
        this.renderSelectReview()
      )
    );
  }

  renderAdvisor() {
    console.log('in renderAdvisor');
    const { auth } = this.props;
    return DOM.section(
      { className: "rx-check__advisor" },
      DOM.div(
        { className: "" },
        `Associate: ${auth.user.profile.email}`
      )
    );
  }

  renderReviewPriorRx() {
    let navProps = _.pick(this.steps.priorRx, ["header", "handleClickEdit"]);
    return DOM.section(
      { className: "rx-check-step" },
      React.createElement(RxCheckStepNav, navProps),
      this.priorRxEntry()
    );
  }

  renderEditDeviceReading() {
    return React.createElement(
      RxCheckStepLayout,
      this.steps.deviceReading,
      this.deviceReadingEntry({ isEditing: true })
    );
  }

  renderReviewDeviceReading() {
    const navProps = _.pick(this.steps.deviceReading, [
      "header",
      "handleClickEdit"
    ]);
    return DOM.section(
      { className: "rx-check-step" },
      React.createElement(RxCheckStepNav, navProps),
      this.deviceReadingEntry()
    );
  }
  deviceReadingEntry(props) {
    const rx = _.get(this.props, "entries.device_reading[0].rx", {});
    const pd = _.pick(_.get(this.props, "entries.pd[0]", {}), ["bi", "uid"]);
    const subjectId = computeSubjectId(this.props.prescriptionRequest.uid);

    return React.createElement(
      EditableDeviceReading,
      _.assign(
        {
          rx: rx,
          pd: pd,
          subjectId: subjectId,
          age: extractAgeFromSurvey(
            _.get(this.props, "entries.kiosk_survey[0]")
          )
        },
        this.steps.deviceReading,
        props
      )
    );
  }

  priorRxEntry(props) {
    return React.createElement(
      PriorRxStep,
      _.assign(
        {},
        props,
        {
          rxes: _.get(this.props, "entries.prior_rx", []),
          rxTypes: rxTypes
        },
        this.steps.priorRx
      )
    );
  }

  renderEditPriorRx() {
    return React.createElement(
      RxCheckStepLayout,
      this.steps.priorRx,
      this.priorRxEntry({ isEditing: true })
    );
  }

  renderEditAcuity() {
    return React.createElement(
      RxCheckStepLayout,
      this.steps.acuity,
      this.acuityEntry({ isEditing: true })
    );
  }

  renderReviewAcuity() {
    let navProps = _.pick(this.steps.acuity, ["header", "handleClickEdit"]);
    return DOM.section(
      { className: "rx-check-step" },
      React.createElement(RxCheckStepNav, navProps),
      this.acuityEntry({ isEditing: false })
    );
  }

  renderReviewPatient() {
    return DOM.section(
      null,
      React.createElement(RxCheckStepNav, { header: "Customer" }),
      React.createElement(
        Patient,
        _.pick(this.props.patient, ["name", "email"])
      )
    );
  }

  renderEditPatient() {
    return React.createElement(
      RxCheckStepLayout,
      this.steps.patient,
      React.createElement(SelectPatientContainer, {
        userLocation: this.props.userLocation,
        onChangeLocation: this.props.updateLocation.bind(this),
        onSelect: this.patientSelected.bind(this)
      })
    );
  }

  patientSelected(patient_uid, prescription_request_uid) {
    // TODO if userLocation differs, update it now
    this.retrievePatientData(patient_uid, prescription_request_uid);
    this.setState({ selected: null });
  }

  renderReview() {
    return React.createElement(
      RxCheckStepLayout,
      this.steps.review,
      DOM.div({ className: "rx-check__title" }, "Prescription Check"),
      this.renderAdvisor(),
      this.submitPanel(),
      this.renderReviewPatient(),
      this.renderReviewPriorRx(),
      this.renderReviewAcuity({ isEditing: false }),
      this.renderReviewDeviceReading(),
      this.submitPanel()
    );
  }

  returnToCheckout = () => {
    const poeUrl = fns.fetchPOEUrl();
    const patientUid = _.get(this.props, "patient.uid");
    const patientEmail = _.get(this.props, "patient.email");
    const checkoutUrl = new URL(
      `/pos/orders/snoozes?patient_email=${patientEmail}&patient_uid=${patientUid}`,
      poeUrl).toString();
    this.exitApplication(this.props.auth.cleanup, checkoutUrl);
  };

  renderConfirmation() {
    return React.createElement(
      RxCheckStepLayout,
      this.steps.confirmation,
      DOM.div({ className: "rx-check__title px-20" }, "Success!"),
      DOM.div(
        { className: "px-20 mb-20" },
        "Your Prescription Check was submitted."
      ),
      DOM.div(
        { className: "px-20 mb-20" },
        DOM.h3({ className: "u-field-label" }, "Customer"),
        DOM.div({}, _.get(this.props, "patient.name")),
        DOM.div({}, _.get(this.props, "patient.email"))
      ),
      DOM.div(
        { className: "px-20 mb-20" },
        DOM.h3({ className: "u-field-label" }, "Prescription Check"),
        DOM.div(
          {},
          `Status: ${_.get(this.props, "prescriptionRequest.status", "--")}`
        )
      ),
      DOM.div(
        { className: "px-20" },
        DOM.button(
          {
            className: "button secondary",
            onClick: this.steps.confirmation.handleClickNext.bind(this)
          },
          "Start new Prescription Check"
        ),
        DOM.button(
          {
            className: "button",
            onClick: this.returnToCheckout
          },
          "Return to Checkout"
        )
      )
    );
  }

  exitApplication(cleanupFn, redirectUrl) {
    console.log(`AuthRxCheckPatientContainer.exitApplication redirectUrl=`, redirectUrl);
    cleanupFn().then(() => {
      console.log('AuthRxCheckPatientContainer.exitApplication: Clean up succeeded');
    }).catch((error) => {
      console.log("AuthRxCheckPatientContainer.exitApplication: Error in cleanup", error);
    }).finally(() => {
      window.location = redirectUrl;
    });
  }

  // returns the partial function required to submit a patient entry for a type
  submitPatientEntryFunction(entryType, uid) {
    const entryAlreadyExists =
      uid && _.some(_.get(this.props, `entries.${entryType}`), { uid: uid });
    return entryAlreadyExists
      ? _.partial(
          this.props.updatePatientEntry,
          this.props.patient.uid,
          this.props.prescriptionRequest.uid,
          entryType,
          uid
        )
      : _.partial(
          this.props.savePatientEntry,
          this.props.patient.uid,
          this.props.prescriptionRequest.uid,
          entryType
        );
  }

  acuityEntry(props) {
    return React.createElement(
      EditableAcuity,
      _.assign(
        {},
        _.get(this.props, "entries.acuity[0]"),
        this.steps.acuity,
        {
          priorRxType: _.get(this.props, "entries.prior_rx[0].rx.type"),
          rxTypes: rxTypes
        },
        props
      )
    );
  }

  submit() {
    return this.props.signoffPrescriptionRequest(
      this.props.patient.uid,
      this.props.prescriptionRequest.uid,
      "adviser_sign_off",
      { user: _.get(this.props, "user.email") },
      this.props.entries
    );
  }

  submitPanel() {
    const notValid = _.get(this.props, "missingEntries", []).length > 0;
    return [
      React.createElement(Markdown, { unsafeRawMarkdown: SubmissionNote }),
      DOM.button(
        {
          key: "submit",
          className: "button",
          onClick: () => {
            this.setState({ selected: "confirmation" });
            this.submit();
          },
          disabled: notValid
        },
        "Submit Rx Request"
      ),
      notValid
        ? DOM.ul(
            { key: "missing-entries" },
            this.props.missingEntries.map(missingEntry => {
              return DOM.li(
                { key: missingEntry },
                `Missing ${_.startCase(missingEntry)}`
              );
            })
          )
        : undefined
    ];
  }

  render() {
    // TODO handle authprovider error
    if (_.get(this.props, "error.forbidden")) {
      // TODO decide how to get error on props
      return React.createElement(ErrorContainer);
    }
    let renderMainComponent = _.get(
      _.mapValues(this.steps, 'renderFn'),
      this.state.selected,
      this.renderMainMenu
    ).bind(this);
    // since we have per-step definitions containing render functions,
    // re-bind to the correct context
    return React.createElement(
      RxCheckLayout,
      {
        cssModifier: environment === "prod" ? "production" : "training",
        handleClickHeader: () => this.exitApplication(this.props.auth.cleanup, fns.fetchPOEUrl()),
      },
      renderMainComponent()
    );
  }
}
