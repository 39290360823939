import * as _ from "lodash";
import {
  buildAuthRequest,
  simpleAuthRequest,
  fetchJson,
  fetchJsonWithAuth,
  objectToQueryString,
  parseQueryString,
} from "../util";
import { validateRx } from "../validation/rx";
import { createBrowserHistory } from 'history';
import {
  AuthActions,
  AuthContext,
  ErrorActions,
  LocationActions,
  Patient,
  PatientActions,
  PatientEntries,
  PatientEntryActions,
  PrescriptionActions,
  PrescriptionRequest,
  Rx as RxInterface,
  User,
  UserActions,
} from "../actions";

const apiHost = process.env.API_HOST || "";
const appApiHost = process.env.APP_API_HOST || "";
const history = createBrowserHistory();

export function getUser(dispatch, getState): User | Promise<object> {
  const user = getState().user;
  if (user.isFetching || user.data) {
    return user;
  }
  dispatch(UserActions.requestUser());
  return fetchJson(
    `${apiHost}/user/me`,
    simpleAuthRequest(getState),
    dispatch
  ).then(json => {
    dispatch(UserActions.receiveUser(json));
    return json;
  });
}

export function setAuthContext(authContext: AuthContext, dispatch): null {
  dispatch(AuthActions.receiveAuthContext(authContext));
}

export function fetchPatient(
  patientUid,
  dispatch,
  getState
): Patient | Promise<object> {
  const patient = getState().patient;
  if (patient.isFetching || patient.uid === patientUid) {
    return patient;
  }
  dispatch(PatientActions.requestPatient());
  return fetchJson(
    `${apiHost}/patient/${patientUid}`,
    simpleAuthRequest(getState),
    dispatch
  ).then(json => dispatch(PatientActions.receivePatient(json)));
}

export function clearPatient(dispatch): Function {
  dispatch(PatientEntryActions.clearPatientEntries());
  dispatch(PrescriptionActions.clearPrescriptionRequest());
  return dispatch(PatientActions.clearPatient());
}

export function fetchPatientEntries(
  prescriptionRequestUid: string,
  patientUid: string,
  dispatch,
  getState
): Promise<object> {
  return fetchJson(
    `${apiHost}/prescription_request/${prescriptionRequestUid}/patient_entry`,
    simpleAuthRequest(getState),
    dispatch
  ).then(json =>
    dispatch(PatientEntryActions.receivePatientEntries(json.results))
  );
}

function fetchUploadedRx(json, dispatch, getState): Promise<object> | null {
  // Fetch an uploaded Rx, this will have been uploaded to the user and transcribed via Hibbert
  //
  // iHop proxies this call to Springfield, returning a URL that is then stored here

  const springfieldUploadRxIds: string[] | null =
    json.blob.springfield_upload_prescription_request_ids;
  if (springfieldUploadRxIds) {
    // Possible multiple images uploaded, return the most recent
    const springfieldUploadRxId =
      springfieldUploadRxIds[springfieldUploadRxIds.length - 1];

    // Just let the store know a request has been fired
    dispatch(PrescriptionActions.requestUploadedRx());
    return fetchJson(
      `${apiHost}/springfield/prescription_request/${springfieldUploadRxId}`,
      simpleAuthRequest(getState),
      dispatch
    ).then(json => {
      // Return a json blob of form  {'id':<String>, image_url: <String>, status: <String>}
      const recieveUploadAction = PrescriptionActions.receiveUploadedRx(json);
      dispatch(recieveUploadAction);
    });
  }
}

export function fetchPrescriptionRequest(
  patientUid: string,
  prescriptionRequestUid: string,
  dispatch,
  getState
): Promise<object> {
  dispatch(PrescriptionActions.requestPrescriptionRequest());
  return fetchJson(
    `${apiHost}/patient/${patientUid}/prescription_request/${prescriptionRequestUid}`,
    simpleAuthRequest(getState),
    dispatch
  )
    .then(json => {
      dispatch(PrescriptionActions.receivePrescriptionRequest(json));
      return json;
    })
    .then(json => {
      fetchUploadedRx(json, dispatch, getState);
      return json;
    });
}

interface FetchPrescriptionRequestParams {
  source: string;
  status: string;
}

export function fetchPrescriptionRequests(
  params: FetchPrescriptionRequestParams,
  dispatch: Function,
  getState: Function
): Promise<object> {
  dispatch(PrescriptionActions.requestPrescriptionRequests());
  const query = objectToQueryString(params);
  return fetchJson(
    `${apiHost}/prescription_request?${query}`,
    simpleAuthRequest(getState),
    dispatch
  )
    .then(json =>
      dispatch(PrescriptionActions.receivePrescriptionRequests(json.results))
    )
    .catch(error => dispatch(ErrorActions.error(error)));
}

export function updateNotes(
  patientUid: string,
  notes,
  dispatch,
  getState
): Promise<object> {
  return fetchJson(
    `${apiHost}/patient/${patientUid}`,
    buildAuthRequest(getState, {
      body: { notes },
      contentType: "application/json",
      method: "PATCH",
    }),
    dispatch
  ).then(json => dispatch(PatientActions.receivePatient(json)));
}

export function updatePrescriptionRequestStatus(
  patientUid: string,
  prescriptionRequestUid: string,
  transitionName,
  dispatch,
  getState): Promise<object> {
  dispatch({ type: "UPDATE_PRESCRIPTION_REQUEST_STATUS" });
  return fetchJson(
    `${apiHost}/patient/${patientUid}/prescription_request/${prescriptionRequestUid}/${transitionName}`,
    buildAuthRequest(getState, {
      body: {},
      method: "POST",
      contentType: "application/json",
    }),
    dispatch
  ).then(json =>
    dispatch(PrescriptionActions.receivePrescriptionRequest(json))
  );
}

function findMissingEntries(
  entries: object,
  requiredEntries: string[]
): string[] {
  let presentEntries = _.keys(entries);
  return requiredEntries.filter(entry => !_.includes(presentEntries, entry));
}

export function signoffPrescriptionRequest(
  patientUid,
  prescriptionRequestUid,
  type,
  data,
  entries: PatientEntries,
  dispatch,
  getState
) {
  return savePatientEntry(
    patientUid,
    prescriptionRequestUid,
    type,
    data,
    dispatch,
    getState
  )
    .then((prescriptionRequest: PrescriptionRequest) => {
      const missingEntries = findMissingEntries(entries, [
        "prior_rx",
        "acuity",
        "device_reading",
        "pd",
      ]);

      // Grab the current device reading rx, or instantiate a dummy one
      const rx: RxInterface = _.get(entries, "device_reading[0].rx", {
        od: null,
        os: null,
      });
      if (missingEntries.length || !rx || !validateRx(rx)) {
        return {};
      }

      // If there aren't two prior rx entries present we use the old waitingForPriorRx rules,
      // which flag the request as waiting for prior rx if that's explicitly the rx type OR if
      // the only prior rx is for contacts. If there are two or more prior rx entries present,
      // we use the new rules, under which the request is waiting if and only if there's at
      // least one prior rx entry on it of type 'waiting_for_prior_rx'.
      const priorRxEntries = _.get(entries, "prior_rx", []);
      let waitingForPriorRx = false;
      if (priorRxEntries.length < 2) {
        waitingForPriorRx = priorRxEntries.some(function(entry) {
          const rxType = _.get(entry, "rx.type");
          return (
            rxType === "waiting_for_prior_rx" || rxType === "prior_contacts_rx"
          );
        });
      } else {
        waitingForPriorRx = priorRxEntries.some(function(entry) {
          return _.get(entry, "rx.type") === "waiting_for_prior_rx";
        });
      }

      let signoffTransition = waitingForPriorRx
        ? "submit_waiting_rx"
        : "submit";
      if (prescriptionRequest.status === "Waiting for Prior Rx") {
        if (waitingForPriorRx) {
          return {};
        } else {
          signoffTransition = "add_prior_rx";
        }
      }
      return updatePrescriptionRequestStatus(
        patientUid,
        prescriptionRequestUid,
        signoffTransition,
        dispatch,
        getState
      );
    })
    .then(prescriptionRequest => {
      if (
        !_.isEmpty(prescriptionRequest) &&
        !_.get(prescriptionRequest, "prescription_request.blob.conducted_by") // Don't sign off twice
      ) {
        dispatch({ type: "REQUEST_UPDATE_PRESCRIPTION_REQUEST" });
        return fetchJson(
          `${apiHost}/patient/${patientUid}/prescription_request/${prescriptionRequestUid}/sign_off`,
          buildAuthRequest(getState, {
            body: {},
            method: "PATCH",
            contentType: "application/json",
          }),
          dispatch
        ).then(json =>
          dispatch(PrescriptionActions.receivePrescriptionRequest(json))
        );
      }
    });
}

export function savePatientEntry(
  patientUid,
  prescriptionRequestUid,
  type,
  data,
  dispatch,
  getState
) {
  dispatch({ type: "REQUEST_PATIENT_ENTRY" });
  return fetchJson(
    `${apiHost}/patient/${patientUid}/patient_entry`,
    buildAuthRequest(getState, {
      body: _.assign(
        { type: type, prescription_request_uid: prescriptionRequestUid },
        data
      ),
      method: "POST",
      contentType: "application/json",
    }),
    dispatch
  ).then(json => {
    dispatch(PatientEntryActions.receivePatientEntry(json));
    return fetchPrescriptionRequest(
      patientUid,
      prescriptionRequestUid,
      dispatch,
      getState
    );
  });
}

export function updatePatientEntry(
  patientUid,
  prescriptionRequestUid,
  type,
  patientEntryUid,
  data,
  dispatch,
  getState
) {
  dispatch({ type: "REQUEST_PATIENT_ENTRY" });
  return fetchJson(
    `${apiHost}/patient/${patientUid}/patient_entry/${patientEntryUid}`,
    buildAuthRequest(getState, {
      body: _.assign(
        { type: type, prescription_request_uid: prescriptionRequestUid },
        data
      ),
      method: "PUT",
      contentType: "application/json",
    }),
    dispatch
  ).then(json => {
    dispatch(PatientEntryActions.receivePatientEntry(json));
    return fetchPrescriptionRequest(
      patientUid,
      prescriptionRequestUid,
      dispatch,
      getState
    );
  });
}

export function updatePatient(patientUid, updates, dispatch, getState) {
    return (
      fetchJsonWithAuth(
        `${apiHost}/patient/${patientUid}`,
        "PATCH",
        updates,
        dispatch,
        getState
      )
      // TODO remove this intermediate fetch after migrating dal.patient.update_patient to the unwrapped blob format
      // PATCH doesn't unwrap the blob like GET and POST responses,
      // so just refetch the patient to get the response in a consistent format
        .then(() =>
          fetchJson(
            `${apiHost}/patient/${patientUid}`,
            simpleAuthRequest(getState),
            dispatch
          )
        )
        .then(json => dispatch(PatientActions.receivePatient(json)))
    );
}

export function updateRxDate(dateType, date, dispatch) {
  return dispatch({
    type: `UPDATE_RX_${dateType}`,
    date,
  });
}

// export function updateRxNotes(notes, dispatch) {
//   return dispatch({
//     type: "UPDATE_RX_NOTES",
//     notes,
//   });
// }

export function getLocation(location, dispatch) {
  return dispatch(LocationActions.requestLocation(), dispatch);
}

export function updateLocation(location, dispatch) {
  // TODO store this with prescription request, and set upstream
  return dispatch(LocationActions.receiveLocation(location), dispatch);
}

function deblobify(obj) {
  // Remove blob key and spread it across parent.
  return dekeyify(obj, "blob");
}

function dekeyify(obj, key) {
  // Remove key and spread it across parent.
  // Top-level keys have precedence.
  // ({ foo: { blob: { bar: false } } }, 'blob') => { foo: { bar: false } }
  const { [key]: value, ...rest } = obj;
  return { ...value, ...rest };
}

// export function updatePatientNotes(
//   patientUid: string,
//   notes,
//   dispatch
// ): Promise<object> {
//   return fetchJson(
//     `${apiHost}/patient/${patientUid}`,
//     {
//       body: JSON.stringify({ notes }),
//       credentials: "include",
//       headers: { "Content-Type": "application/json" },
//       method: "PATCH",
//     },
//     dispatch
//   )
//     .then(deblobify)
//     .then(json => dispatch(PatientActions.receivePatient(json)));
// }
//
// export function recommendationReady(
//   patientUid,
//   prescriptionRequestUid,
//   rx,
//   dispatch
// ) {
//   return fetchJson(
//     `${apiHost}/patient/${patientUid}/recommendation_ready`,
//     {
//       body: JSON.stringify({
//         recommendation: rx,
//         prescription_request_uid: prescriptionRequestUid,
//       }),
//       credentials: "include",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       method: "POST",
//     },
//     dispatch
//   )
//     .then(function(json) {
//       dispatch({
//         type: "RECEIVE_PATIENT_ENTRY",
//         entry: json.patient_entry,
//       });
//       return updatePrescriptionRequestStatus(
//         patientUid,
//         prescriptionRequestUid,
//         "ready",
//         dispatch
//       );
//     })
//     .then(() => history.push("/"));
// }
//
// export function recommendationRefer(
//   patientUid,
//   prescriptionRequestUid,
//   rx,
//   dispatch
// ) {
//   fetchJson(
//     `${apiHost}/patient/${patientUid}/recommendation_refer`,
//     {
//       body: JSON.stringify({
//         recommendation: rx,
//         prescription_request_uid: prescriptionRequestUid,
//       }),
//       credentials: "include",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       method: "POST",
//     },
//     dispatch
//   )
//     .then(function(json) {
//       dispatch({
//         type: "RECEIVE_PATIENT_ENTRY",
//         entry: json.patient_entry,
//       });
//       return updatePrescriptionRequestStatus(
//         patientUid,
//         prescriptionRequestUid,
//         "refer",
//         dispatch
//       );
//     })
//     .then(() => history.push("/"));
// }

const SUPPORTED_POE_HOSTS = ["warby.io", "wp-dev.io", "wp-local.com"];

export function fetchPOEUrl(): String {
  const next = parseQueryString(window.location.search).next || "";
  try {
    const nextHost = new window.URL(next).host;
    for (const poeHost of SUPPORTED_POE_HOSTS) {
      if (nextHost.substr(-poeHost.length) === poeHost) {
        return next;
      }
    }
  } catch (TypeError) {}
  return process.env.RETAIL_DOMAIN || "https://poe.warby.io";
}

export function fetchPOELocation(): string | null {
  return parseQueryString(window.location.search).userLocation || null;
}

// export function fetchSpringfieldPrescriptionUpload(
//   springfieldPrescriptionRequestId: string,
//   dispatch: Function
// ): Promise<object> | null {
//   if (_.isNil(springfieldPrescriptionRequestId)) {
//     return null;
//   } else {
//     return fetchJson(
//       `${apiHost}/springfield/prescription_request/${springfieldPrescriptionRequestId}`,
//       { credentials: "include" },
//       dispatch
//     ).then(function(json) {
//       dispatch({
//         type: "RECEIVE_SPRINGFIELD_UPLOAD_PRESCRIPTION_REQUEST",
//         prescriptionRequest: json,
//       });
//       return json;
//     });
//   }
// }
