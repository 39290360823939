import * as React from "react";
import DOM from "react-dom-factories";
import * as _ from "lodash";
import {KeypadComponent, KeypadMode} from "./RxKeypad";


function updatePower(currentPower: string, value: string): string {
  let components = currentPower.match(/([-+]?)([0-9]+)(\.[0-9]+)?/);
  if (components === null || components === undefined) {
    components = ['', '-', '0', ''];
  }
  let newValue = '';
  if (value[0] == '.') {
    newValue = [components[1], components[2], value].join('');
  } else if (value === "-" || value === "+") {
    newValue = [value, components[2], components[3]].join('');
  } else {
    newValue = [components[1], value, components[3]].join('');
  }
  // remove sign if 0.00
  return /[-+]0.00/.test(newValue) ? '0.00' : newValue;
}

interface PrescriptionTableProps {
  rx: any;
  handleChangeRx: (any) => void;
  isEditing: boolean;
}

interface PrescriptionTableState {
  side: string;
  measure: string;
}


export default class PrescriptionTableWithKeypad extends React.Component<PrescriptionTableProps, PrescriptionTableState> {
  constructor(props: PrescriptionTableProps) {
    super(props);
    this.state = {
      side: null,
      measure: null
    }
  }

  renderCell(side, measure) {
    let value = _.get(this.props.rx, [side, measure]);
    const selected = this.state.side == side && this.state.measure == measure;

    return DOM.td({
        className: selected ? 'prescription-table-selected' : '',
        onClick: () => this.setState({side, measure})
      },
      value
    )
  }

  showKeypad() {
    return this.state.side !== null && this.state.measure !== null;
  }

  updateRxValue(value) {
    if (this.state.side === null || this.state.measure === null) return;
    const currentValue = _.get(this.props.rx, [this.state.side, this.state.measure], '');
    let nextValue;
    if (this.state.measure == 'axis') {
      nextValue = currentValue + value;
      if (Number(nextValue) > 180) {
        nextValue = value;
      }
    } else {
      nextValue = updatePower(currentValue, value);
    }

    const rx = _.assign({}, this.props.rx);
    return this.props.handleChangeRx(_.set(rx, [this.state.side, this.state.measure], nextValue));
  }

  clearSelected(ev) {
    ev.preventDefault();
    const rx = _.assign({}, this.props.rx);
    return this.props.handleChangeRx(_.set(rx, [this.state.side, this.state.measure], ''));
  }

  render() {
    return DOM.div(null,
      DOM.table({className: 'u-table--rx'},
        DOM.thead(null,
          DOM.tr(null,
            DOM.th(null, ''),
            DOM.th(null, 'SPH'),
            DOM.th(null, 'CYL'),
            DOM.th(null, 'AXIS')
          )
        ),
        DOM.tbody(null,
          DOM.tr(null,
            DOM.th(null, 'OD'),
            this.renderCell('od', 'sph'),
            this.renderCell('od', 'cyl'),
            this.renderCell('od', 'axis'),
          ), DOM.tr(null,
            DOM.th(null, 'OS'),
            this.renderCell('os', 'sph'),
            this.renderCell('os', 'cyl'),
            this.renderCell('os', 'axis'),
          )
        )),
      this.showKeypad() ? DOM.div(null,
        DOM.button({
          className: "prescription-table-clear-button",
          onClick: this.clearSelected.bind(this)
        }, 'clear'),
        React.createElement(KeypadComponent, {
          onClick: (value) => this.updateRxValue(value),
          mode: this.state.measure == 'axis' ? KeypadMode.KeypadAxisMode : KeypadMode.KeypadPowerMode
        })) : undefined
    );
  }
}
