import { UserManager } from "oidc-client";
import React from "react";

/**
 * This is from https://github.com/WarbyParker/identity-provider/blob/master/frontend/src/components/Auth.js
 * @warbyparker/auth doesn't yet support silent refresh
 *
 * This context pair allows us to inject the AuthContext into components
 * at any level of the tree without requiring all intermediate components
 * to pass the reference through their prop chain.
 *
 * Typically these will not be used directly. The `AuthenticationGate`
 * wraps the Provider, and `withAuthContext` can be used to create a
 * component to uses the Consumer.
 */

/**
 * This is the value object that is provided and consumed by the Context.
 */
class AuthState {
  constructor({user, isPending, error, login, cleanup}) {
    this.user = user;
    this.isPending = isPending;
    this.error = error;
    this.login = login;
    this.cleanup = cleanup;
  }
}


const AuthContext = React.createContext();
AuthContext.Provider.displayName = "Auth.Provider";
AuthContext.Consumer.displayName = "Auth.Consumer";

export class AuthProvider extends React.Component {
  constructor(props) {
    super(props);
    let settings = {
      authority: process.env.OIDC_AUTHORITY,
      client_id: process.env.OIDC_CLIENT_ID,
      redirect_uri: `${global.location.origin}/oidc-callback`,
    };
    if (process.env.OIDC_SCOPE) {
      settings.scope = process.env.OIDC_SCOPE;
    }
    this.userManager = this.createUserManager(settings);

    let oidcPaths = {};
    if (this.userManager.settings.silent_redirect_uri)
      oidcPaths.silentRedirect = new URL(this.userManager.settings.silent_redirect_uri).pathname;
    if (this.userManager.settings.redirect_uri)
      oidcPaths.redirect = new URL(this.userManager.settings.redirect_uri).pathname;
    if (this.userManager.settings.post_logout_redirect_uri)
      oidcPaths.postLogoutRedirect = new URL(this.userManager.settings.post_logout_redirect_uri).pathname;
    this.oidcPaths = oidcPaths;
  }

  createUserManager(settings) {
    if (!settings || !settings.client_id) {
      throw new Error("OIDC client_id is required.");
    }
    if (!settings || !settings.authority) {
      throw new Error("OIDC issuer is required.");
    }

    const defaults = {
      response_type: "id_token token",
      scope: "openid profile idp:session",
      // This page is where the initial login is redirected to on successful
      // authentication. It should equal a redirect_url in the config:
      // https://github.com/WarbyParker/identity-provider/blob/master/api/clients/advisor-portal
      redirect_uri: `${global.location.origin}/oidc-callback-signin`,
      // This page is where silent refresh is redirected to on successful reauthentication.
      // It should equal a redirect_url in the config:
      // https://github.com/WarbyParker/identity-provider/blob/master/api/clients/advisor-portal
      silent_redirect_uri: `${global.location.origin}/oidc-callback-silent`,
      // This page is where silent refresh is redirected to on successful logout.
      // It should equal a post_logout_redirect_url in the config:
      // https://github.com/WarbyParker/identity-provider/blob/master/api/clients/advisor-portal
      post_logout_redirect_uri: `${global.location.origin}/oidc-callback-signout`,
      // The user info endpoint doesn't return any more info than the id token.
      loadUserInfo: false,
    };

    return new UserManager({ ...defaults, ...settings });
  }

  state = {
    user: null,
    error: null,
    isPending: true,
    silentRefresh: false,
  };

  onSigninSuccess = (user) => {
    console.log("AuthProvider.onSigninSuccess", user);
    this.setState({user: user, error: null, isPending: false});
  };

  onSigninError = (error) => {
    console.log("AuthProvider.onSigninError", error);
    this.setState({error: error, isPending: false});
  };

  onSignoutSuccess = (user) => {
    console.log("AuthProvider.onSignoutSuccess", user);
    this.setState({user: null, error: null, isPending: false});
  };

  onSignoutError = (error) => {
    console.log("AuthProvider.onSignoutError", error);
    this.setState({error: error, isPending: false});
  };

  onUserLoaded = (user) => {
    console.log("AuthProvider.onUserLoaded", user);
    this.setState({user: user, error: null, isPending: false});
  };

  onUserUnloaded = (user) => {
    console.log("AuthProvider.onUserUnloaded", user);
    this.setState({user: user, error: null, isPending: false});
  };

  onAccessTokenExpiring = (user) => {
    console.log("AuthProvider.onAccessTokenExpiring", user);
    if (this.state.silentRefresh) {
      // We should get here only if we've triggered the code path below
      // (starting silentRefresh)
      console.log("AuthProvider.silentRefresh already in progress.");
    } else {
      console.log("AuthProvider.starting silentRefresh", user);
      this.setState({silentRefresh: true});
      this.userManager.signinSilent()
        .then(user => this.setState({user: user, silentRefresh: false}))
        .catch(error => this.setState({error: error, silentRefresh: false}));
    }
  };

  onAccessTokenExpired = (user) => {
    console.log("AuthProvider.onAccessTokenExpired", user);
    this.login();
  };

  onSilentRenewSuccess = (user) => {
    console.log("AuthProvider.onSilentRenewSuccess", user);
    this.setState({user: user, isPending: false, silentRefresh: false});
  };

  onSilentRenewError = (error) => {
    console.log("AuthProvider.onSilentRenewError", error);
    this.setState({error: error, isPending: false, silentRefresh: false});
  };

  onUserSignedOut = (user) => {
    console.log("AuthProvider.onUserSignedOut", user);
    this.setState({user: user, error: null, isPending: false});
  };

  login = () => {
    // Stop handling UserManager events before starting the login. This ensures
    // that downstream components that utilize the auth state don't try to
    // re-render while a redirect is imminent.
    this.unlisten();
    this.setState({error: null, isPending: true});
    // promise unhandled because redirect is expected
    this.userManager.signinRedirect();
  };

  handleOidcCallbacks() {
    switch (global.location.pathname) {
      case this.oidcPaths.silentRedirect:
        console.log(`AuthProvider.handleOidcCallbacks: silent_redirect_uri (${global.location.pathname})`);
        return this.userManager.signinSilentCallback()
          .then(user => {
            console.log(`AuthProvider.handleOidcCallbacks: userManager.signinSilentCallback success`, user);
          })
          .catch(error => {
            console.log(`AuthProvider.handleOidcCallbacks: userManager.signinSilentCallback errpr`, error);
          });
      case this.oidcPaths.redirect:
        console.log(`AuthProvider.handleOidcCallbacks: redirect_uri (${global.location.pathname})`);
        return this.userManager.signinRedirectCallback()
          .then(user => {
            console.log(`AuthProvider.handleOidcCallbacks: userManager.signinRedirectCallback success`, user);
            this.setState({user: user, error: null, isPending: false});
            if (this.props.onSigninSuccess) {
              this.props.onSigninSuccess(user);
            }
          })
          .catch(error => {
            console.log(`AuthProvider.handleOidcCallbacks: userManager.signinRedirectCallback errpr`, error);
            this.setState({error: error, isPending: false});
          });
      case this.oidcPaths.postLogoutRedirect:
        console.log(`AuthProvider.handleOidcCallbacks: post_logout_redirect_uri (${global.location.pathname})`);
        return this.userManager.signoutRedirectCallback()
          .then(() => {
            console.log(`AuthProvider.handleOidcCallbacks: userManager.signoutRedirectCallback success`);
            if (this.props.onSignoutSuccess) {
              this.props.onSignoutSuccess();
            }
          })
          .catch(error => {
            console.log(`AuthProvider.handleOidcCallbacks: userManager.signoutRedirectCallback errpr`, error);
            this.setState({error: error, isPending: false});
          });
      default:
        console.log(`AuthProvider.handleOidcCallbacks: default ${global.location.pathname}`);
        // Authentication has already happened; let the app take control
        return Promise.resolve(null);
    }
  }

  async componentDidMount() {
    await this.handleOidcCallbacks(global);

    this.userManager.getUser().then(user => this.onUserLoaded(user));
    this.userManager.events.addUserLoaded(this.onUserLoaded);
    this.userManager.events.addUserUnloaded(this.onUserUnloaded);
    this.userManager.events.addAccessTokenExpiring(this.onAccessTokenExpiring);
    this.userManager.events.addAccessTokenExpired(this.onAccessTokenExpired);
    this.userManager.events.addSilentRenewError(this.onSilentRenewError);
    this.userManager.events.addUserSignedOut(this.onUserSignedOut);

    this.unlisten = () => {
      this.userManager.events.removeUserLoaded(this.onUserLoaded);
      this.userManager.events.removeUserUnloaded(this.onUserUnloaded);
      this.userManager.events.removeAccessTokenExpiring(this.onAccessTokenExpiring);
      this.userManager.events.removeAccessTokenExpired(this.onAccessTokenExpired);
      this.userManager.events.removeSilentRenewError(this.onSilentRenewError);
      this.userManager.events.removeUserSignedOut(this.onUserSignedOut);
    };
  }

  async cleanup() {
    console.log("AuthProvider.cleanup: UserManager.signoutRedirect", this);
    this.setState({isPending: true});
    this.unlisten();
    return this.userManager.removeUser();
  };

  componentWillUnmount() {
    console.log("AuthProvider.componentWillUnmount: unlisten to events");
    this.userManager.getUser()
      .then((user) => {
        console.log("AuthProvider.componentWillUnmount: user still in UserManager");
        if (user !== undefined) {
          this.cleanup()
            .then(() => {
              console.log("AuthProvider.componentWillUnmount: cleanup succeeded");
            })
            .catch((error) => {
              console.log("AuthProvider.componentWillUnmount: cleanup error", error);
            });
        } else {
          this.unlisten();
        }
      })
  }

  render() {
    const context = new AuthState({
      user: this.state.user,
      isPending: this.state.isPending,
      error: this.state.error,
      login: this.login,
      cleanup: () => this.cleanup(),
    });
    return <AuthContext.Provider
      value={context}
      children={this.props.children || null}
    />;
  }

}

/**
 * Higher Order Component function that will ensure the wrapped component
 * receives the closest `AuthContext` instance as the `auth` prop.
 */
export function withAuthContext(WrappedComponent) {
  class WithAuthContext extends React.Component {
    render() {
      console.log('WithAuthContext.render', this.props);
      return (
        <AuthContext.Consumer>
          {auth => <WrappedComponent auth={auth} {...this.props} />}
        </AuthContext.Consumer>
      );
    }
  }
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";
  WithAuthContext.displayName = `WithAuthContext(${displayName})`;
  return WithAuthContext;
};
