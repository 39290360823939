import PropTypes from 'prop-types';
import React from 'react';
import DOM from 'react-dom-factories';
import RxCheckStepNav from './RxCheckStepNav';
import _ from 'lodash';

export default class RxCheckStepLayout extends React.Component {
  static get propTypes() {
    return {
      step: PropTypes.number,
      header: PropTypes.string,
      message: PropTypes.string,
      handleClickBack: PropTypes.func
    }
  }

  renderMessage() {
    if (!_.isNil(this.props.message)) {
      return DOM.div({className: 'rx-check-step__message'}, this.props.message);
    }
  }

  render() {
    return DOM.section({className: 'rx-check-step'},
      React.createElement(RxCheckStepNav, _.pick(this.props, ['step', 'header', 'handleClickBack'])),
      this.renderMessage(),
      this.props.children);
  }
}
