import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import DOM from 'react-dom-factories';
import Markdown from './TrustedUnsafeMarkdown';
import PrescriptionTable from './PrescriptionTableWithKeypad';
import PdTable from './PdTable';
import FormLabel from './form/FormLabel';
import { validateRxNotEmpty, validatePdNotEmpty } from '../validation/rx';
import DeviceReadingNote from '../../copy/device_reading.md';
import LightingNote from '../../copy/lighting.md';

window.WP = _.assignIn({
  RxCheckDevice: {
    onReadingReceived: ()=>{},
    onReadingFailed: ()=>{},
    requestReading: ()=>{},
    automaticReadingAvailable: false
  }
}, window.WP)

class AutomaticDeviceReading extends React.Component {
  static get propTypes() {
    return {
      rx: PropTypes.any,
      pd: PropTypes.any,
      age: PropTypes.any,
      subjectId: PropTypes.any,
      isEditing: PropTypes.bool,
      handleSubmit: PropTypes.func.isRequired,
      handleClickBack: PropTypes.func,
      handleClickNext: PropTypes.func,
      submitText: PropTypes.string
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      hasResult: false,
      rx: {},
      pd: {},
      readingFailed: false,
      readingFailedReason: ""
    }
  }

  setStateFromProps(props) {
    this.setState({
      isEditing: _.get(props, 'isEditing'),
      rx: props.rx,
      pd: props.pd
    });
  }

  componentWillMount() {
    this.setStateFromProps(this.props);
  }

  componentDidMount() {
      this.requestReading()
  }

  componentWillReceiveProps(props) {
    this.setStateFromProps(props);
  }

  handleReading(readingRecord) {
    if(readingRecord.subjectID.toLowerCase() != this.props.subjectId.toLowerCase()) {
      console.log("Skipping record", readingRecord.subjectID, readingRecord.recordID)
      return;
    }
    this.setState({
      hasResult: true,
      rx: {
        od: {
          sph: readingRecord.odSph,
          cyl: readingRecord.odCyl,
          axis: readingRecord.odAxis,
        },
        os: {
          sph: readingRecord.osSph,
          cyl: readingRecord.osCyl,
          axis: readingRecord.osAxis,
        },
        deviceData: readingRecord.rawResults,
        notes: _.get(this.state, 'rx.notes'),
      },
      pd: {
        bi: readingRecord.biPd,
      },
    })
  }
  onReadingFailed(reason) {
    this.setState({
      hasResults: false,
      readingFailed: true,
      readingFailedReason: reason
    })
  }
  requestReading() {
    window.WP.RxCheckDevice.onReadingReceived = _.bind(this.handleReading, this)
    // TODO: end timer interval on reading failed
    window.WP.RxCheckDevice.onReadingFailed = _.bind(this.onReadingFailed, this)
    window.WP.RxCheckDevice.requestReading()
    this.setState({readingRequestedAt: (new Date()).getTime()})
    let interval = setInterval(_.bind(()=>{
      let timeSinceStart = (new Date()).getTime() - this.state.readingRequestedAt
      if(this.state.hasResult) {
        clearInterval(interval)
      } else if(timeSinceStart > 10*60*1000) {
        this.onReadingFailed('Timed out')
        clearInterval(interval)
      } else {
        console.log("Re-requesting")
        window.WP.RxCheckDevice.requestReading()
      }
    }, this), 10000)
  }

  handleSubmit() {
    const data = _.pick(this.state, ['rx', 'pd']);
    this.props.handleSubmit(data).then(this.props.handleClickNext());
  }

  renderSave(){
    return this.renderButton(this.props.submitText, (e) => this.handleSubmit(e))
  }

  renderButton(text, onClick) {
    if (this.props.isEditing) {
      return DOM.button({
        className: 'button',
        key: 'submit-device-reading',
        type: 'submit',
        disabled: false,
        onClick: onClick
      }, text);
    }
  }

  renderNotes() {
    const notes = _.get(this.state, 'rx.notes');
    return DOM.div({className: 'mb-20'},
      React.createElement(FormLabel, {className: 'u-field-label', label: 'notes'},
        DOM.textarea({
          className: 'u-field-input--textarea',
          value: notes,
          onChange: (ev) => this.setState({rx: _.assign(this.state.rx, {notes: ev.target.value})})
        })));
  }

  renderResult() {
    return [
      React.createElement(PrescriptionTable, {
        rx: this.state.rx,
        handleChangeRx: () => {},
        isEditable: false,
        isEditing: false,
      }),
      React.createElement(PdTable, {
        pd: this.state.pd
      }),
      this.renderNotes(),
      DOM.hr(),
      this.renderSave(),
    ]
  }

  renderWaitingForRx() {
    return [
      DOM.h1(null, 'Please enter the following information into the device'),
      DOM.hr(),
      DOM.dl({className: 'automatic-reading__instructions'},
        DOM.dt(null, 'Subject ID'),
          DOM.dd(null, this.props.subjectId),
        DOM.dt(null, 'First & Last Name'),
          DOM.dd(null, 'press OK to skip'),
        DOM.dt(null, 'Age'),
          DOM.dd(null, ''+this.props.age+' years'),
      ),
      DOM.hr(),
      DOM.h1({className: 'automatic-reading__message'}, 'Waiting for device Rx'),
      DOM.hr(),
      this.renderButton('Cancel', (e) => this.props.handleClickBack(e))
    ]
  }

  renderFailed() {
    return [
      DOM.hr(),
      DOM.h1({
        className: 'automatic-reading__message'},
        `Device connection failed. Reason: ${this.state.readingFailedReason}`
      ),
      DOM.h2(null,
        'Please select ‘Cancel’ to try again or go back to enter results manually.'),
      DOM.hr(),
      this.renderButton('Cancel', (e) => this.props.handleClickBack(e))
    ]
  }

  renderContents() {
    if(this.state.hasResult) {
      return this.renderResult()
    } else if(this.state.readingFailed) {
      return this.renderFailed()
    } else if(this.state.readingRequestedAt) {
      return this.renderWaitingForRx()
    } else {
      return this.renderWaitingForRx()
    }
  }

  render() {
    return DOM.div({className: 'rx-check-step__detail'},
      ...this.renderContents());
  }
}

class ManualDeviceReading extends React.Component {
  static get propTypes() {
    return {
      rx: PropTypes.any,
      pd: PropTypes.any,
      isEditing: PropTypes.bool,
      handleSubmit: PropTypes.func.isRequired,
      handleClickBack: PropTypes.func,
      handleClickNext: PropTypes.func,
    }
  }

  componentWillMount() {
    this.setStateFromProps(this.props);
  }

  componentWillReceiveProps(props) {
    this.setStateFromProps(props);
  }

  setStateFromProps(props) {
    this.setState({
      isEditing: _.get(props, 'isEditing', false),
      rx: props.rx,
      pd: props.pd
    });
  }

  updateValidation(validationValues) {
    const validation = _.get(this.state, 'validation', {});
    const newValidation = {validation: _.assign({}, validation, validationValues)};
    this.setState({validation: _.assign({}, validation, validationValues)});
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = _.pick(this.state, ['rx', 'pd']);
    const rx = validateRxNotEmpty(data.rx);
    const pd = validatePdNotEmpty(data.pd);
    this.updateValidation({rx: rx, pd: pd});
    if (rx.valid && pd.valid) {
      this.props.handleSubmit(data).then(this.props.handleClickNext());
    } else if (rx.errors.length === 0 && pd.errors.length === 0) {
      if (_.get(this.state, 'showWarnings', false)) {
        this.props.handleSubmit(data).then(this.props.handleClickNext());
      } else {
        this.setState({showWarnings: true});
      }
    }
  }

  renderSave(){
    if (this.props.isEditing) {
      const message = this.state.showWarnings ? 'Submit with warnings' : 'Save and continue';
      return DOM.button({
        className: `button ${this.state.showWarnings ? 'warn' : ''}`,
        key: 'submit-device-reading',
        type: 'submit',
        disabled: false,
        onClick: (e) => this.handleSubmit(e)
      }, message);
    }
  }

  renderBiPd() {
    const errors = _.get(this.state, 'validation.pd.errors', []);
    const warnings = _.get(this.state, 'validation.pd.warnings', []);
    // The device reading only measures PD in bi
    return DOM.div({className: 'mb-20'},
      errors.map((error, i) => DOM.div({className: 'u-field-error', key: i}, error)),
      warnings.map((warning, i) => DOM.div({className: 'u-field-warning', key: i}, warning)),
      React.createElement(FormLabel, {
        className: 'u-field-label mb-20',
        label: 'binocular pd'},
        DOM.input({
          className: 'u-field-input',
          onChange: (ev) => this.setState({pd: _.assign(this.state.pd, {bi: ev.target.value})}),
          key: 'bipd',
          name: 'bipd',
          value: _.get(this.state, 'pd.bi', '')
        })));
  }

  renderRxTable() {
    const errors = _.get(this.state, 'validation.rx.errors', []);
    const warnings = _.get(this.state, 'validation.rx.warnings', []);
    return DOM.div({className: 'mb-20'},
      errors.map((error, i) => DOM.div({className: 'u-field-error', key: i}, error)),
      warnings.map((warning, i) => DOM.div({className: 'u-field-warning', key: i}, warning)),
      React.createElement(PrescriptionTable, {
        rx: this.state.rx,
        handleChangeRx: (rx) => this.setState({rx: rx}),
        isEditable: true,
        isEditing: this.props.isEditing
    }));
  }

  renderForm() {
    if (this.props.isEditing) {
      return DOM.form({onSubmit: this.handleSubmit.bind(this)},
        DOM.div(null,
          React.createElement(
            Markdown,
            {unsafeRawMarkdown: DeviceReadingNote}
          ),
          this.renderRxTable(),
          this.renderBiPd(),
          this.renderNotes(),
          React.createElement(
            Markdown,
            {unsafeRawMarkdown: LightingNote}
          ),
          this.renderSave()));
    } else {
      return DOM.div(null,
        this.renderRxTable(),
        this.renderNotes());
    }
  }

  renderNotes() {
    const notes = _.get(this.state, 'rx.notes');
    if (this.props.isEditing) {
      return DOM.div({className: 'mb-20'},
        React.createElement(FormLabel, {className: 'u-field-label', label: 'notes'},
          DOM.textarea({
            className: 'u-field-input--textarea',
            value: notes,
            onChange: (ev) => this.setState({rx: _.assign(this.state.rx, {notes: ev.target.value})})
          })));
    } else {
      return DOM.div(null, notes);
    }
  }

  render() {
    return DOM.div({className: 'rx-check-step__detail'},
      this.renderForm());
  }
}

export default class EditableDeviceReading extends React.Component {
  static get propTypes() {
    return {
      rx: PropTypes.any,
      pd: PropTypes.any,
      isEditing: PropTypes.bool,
      handleSubmit: PropTypes.func.isRequired,
      handleClickBack: PropTypes.func,
      handleClickNext: PropTypes.func,
      submitText: PropTypes.string,

      age: PropTypes.any,
      subjectId: PropTypes.any,
    }
  }

  static get defaultProps() {
    return {
      submitText: 'Save and continue',
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      rx: {},
      pd: {},
      selectedMethod: null,
    }
  }

  componentWillMount() {
    this.setStateFromProps(this.props);
  }

  componentWillReceiveProps(props) {
    this.setStateFromProps(props);
  }

  setStateFromProps(props) {
    this.setState({
      isEditing: _.get(props, 'isEditing'),
      rx: props.rx,
      pd: props.pd
    });
  }

  renderMethodSelector() {
    return DOM.div(null,
      DOM.div(
        {className: 'rx-check-step__message ta-l'},
        'Device reading entry type:'
      ),
      DOM.button({
        className: 'u-button-list-item tt-c',
        onClick: () => this.setState({selectedMethod: 'automatic'})
      }, 'Automatic from device'),
      DOM.button({
        className: 'u-button-list-item tt-c',
        onClick: () => this.setState({selectedMethod: 'manual'})
      }, 'Manually entered'),
    )
  }

  renderManual() {
    let props = this.props
    if(!_.isNil(this.state.selectedMethod)) {
      let props = _.assignIn({},
        this.props,
        {'handleClickBack': () => this.setState({'selectedMethod': null})}
      )
    }
    return React.createElement(ManualDeviceReading, this.props);
  }

  renderAutomatic() {
    const props = _.assignIn({},
      this.props,
      {'handleClickBack': () => this.setState({'selectedMethod': null})}
    )
    return React.createElement(AutomaticDeviceReading, props);
  }

  renderContents() {
    if(!window.WP.RxCheckDevice.automaticReadingAvailable || !this.state.isEditing) {
      return this.renderManual()
    }
    if(_.isNil(this.state.selectedMethod)) {
      return this.renderMethodSelector();
    }
    switch(this.state.selectedMethod){
      case 'automatic': return this.renderAutomatic();
      case 'manual': return this.renderManual();
    }
    throw new Error('invalid method:' + this.state.selectedMethod)
  }

  render() {
    return DOM.div({className: 'rx-check-step__detail'},
      this.renderContents());
  }
}
