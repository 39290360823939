import PropTypes from 'prop-types';
import React from 'react';
import DOM from 'react-dom-factories';

export default class RxCheckHeader extends React.Component {
  static get propTypes() {
    return {
      handleClickHeader: PropTypes.func,
      headerUrl: PropTypes.string,
      cssModifier: PropTypes.string
    };
  }

  static get defaultProps() {
    return {
      cssModifier: ""
    };
  }

  render() {
    const imageWrapProps = { className: "logo" };
    if (this.props.handleClickHeader) {
      imageWrapProps["onClick"] = this.props.handleClickHeader
    } else if (this.props.headerUrl) {
      imageWrapProps["href"] = this.props.headerUrl;
    }

    return DOM.div(
      { className: `${this.props.cssModifier} rx-check-header` },
      DOM[this.props.headerUrl ? "a" : "div"](
        imageWrapProps,
        DOM.img({
          src: "./static/images/logo.svg"
        })
      )
    );
  }
}
