import PropTypes from 'prop-types';
import React from 'react';
import DOM from 'react-dom-factories';

export default class Patient extends React.Component {
  static get propTypes() {
    return {
      email: PropTypes.string.isRequired,
      name: PropTypes.string
    }
  }
  static get defaultProps() {
    return {
      name: ''
    };
  }

  render() {
    return DOM.div({className: 'u-section-body'},
      DOM.div({className: 'name'}, this.props.name),
      DOM.div({className: 'email'}, this.props.email));
  }
}
