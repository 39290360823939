import React from "react";
import { render } from "react-dom";
import { HashRouter as Router, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import reducer from "./reducers/rxCheckReducer";
import {
  AuthReduxRouterRxCheckPatientContainer,
} from "./containers/WrappedRxCheckPatientContainer";
import { errorFunction } from "./util";

import "../static/styles/index.less";
import { AuthProvider, withAuthContext } from "./containers/AuthProvider";
import { withRouter } from "react-router-dom";
import Error from "./presentational/Error";
import Loading from "./presentational/Loading";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  {},
  composeEnhancers(applyMiddleware(thunkMiddleware))
);

// Attach a global error handler, simply pushes the error to the server
window.onerror = errorFunction("rxCheck");

const signedIn = (user) => {
  console.log("AuthProvider.signedIn: Signed in successfully.", user);
  console.log("AuthProvider.signedIn: after replace state", window.location.href);
};

const signedOut = () => {
  console.log("AuthProvider.signedOut: Signed out successfully. Redirecting to POE.");
  window.location = process.env.RETAIL_DOMAIN || "https://poe.warby.io";
};

const Routes = withRouter(class extends React.Component {
  render() {
    return (
      <Route>
        <AuthReduxRouterRxCheckPatientContainer/>
      </Route>
    );
  }
});

const RouterWithAuth = withAuthContext(class extends React.Component {
  render() {
    const { auth } = this.props;
    if (auth.error) {
      return <Error error={auth.error} />;
    }
    if (auth.isPending) {
      return <Loading />;
    }
    if (!auth.user) {
      auth.login();
      return <Loading />;
    } else {
      return (
        <Router>
          <Routes />
        </Router>
      );
    }
  }
});

render(
  <AuthProvider onSigninSuccess={signedIn} onSignoutSuccess={signedOut}>
    <Provider store={store}>
      <RouterWithAuth />
    </Provider>
  </AuthProvider>,
  document.getElementById("app")
);
