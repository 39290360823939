import * as locations from  '../locations.json';
import * as _ from 'lodash';
import { VALID, ValidationResponse } from './validation';

export function validateLocation(location: string): ValidationResponse {
  if (_.some(locations, (l, id) => {return location === id || location === l.id;})) {
    return VALID;
  }
  return {
    valid: false,
    errors: ['Missing required store userLocation'],
    warnings: []
  };
}
