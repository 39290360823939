import React from 'react';
import DOM from 'react-dom-factories';

export default class ErrorComponent extends React.Component {
  render() {
    if (this.props.error || this.props.forbidden) {
      return DOM.div(null,
        this.props.error && DOM.div({className: 'c-red bg-gray p-10'}, 'Error: ' + this.props.error.message),
        this.props.forbidden && DOM.h1({key: 'message', className: 'c-red bg-gray p-10'}, 'Forbidden'),
        this.props.forbidden && DOM.h2({key: 'explanation', className: 'bg-gray p-10'},
          "If you feel you've received this message in error, please contact customer support.")
      );
    } else {
      return null;
    }
  }
}
