import * as _ from 'lodash';
import { ValidationResponse, MESSAGES, RANGES, VALID } from "./validation";
import { RxType } from "./rx";
import { Acuity, CorrectionType } from "../actions";

export const acuityDenominators =
  ['10', '15', '20', '25', '30', '40', '50', '60', '70', '80', '100', '150', '200', '200+'];
export const correctionTypes = ['glasses', 'contacts', 'no_correction'];
export const acuityLookup = {
  // Acuity `correction`s are matched to Prescriptions by `type`.
  prior_glasses_rx: 'glasses',
  prior_neutralized_rx: 'glasses',
  prior_contacts_rx: 'contacts'
};

// Warnings
// • Between 20/50 and 20/200 -  recommend eye exam
// • If between 20/10 and 20/40 - you're fine
export function validateAcuity(acuity:string=''): ValidationResponse {
  let errors: string[] = [];

  if (acuityDenominators.indexOf(acuity) < 0) {
    errors = errors.concat(MESSAGES.ACUITY_ALLOWED_VALUES)
  }

  // Strip all characters from the acuity string other than digits, hyphens (to indicate negative
  // numbers), and decimal points. This is so that "200+", for example, casts to a real number.
  if (Number(acuity.replace(/[^.\-\d]/g, '')) > RANGES.error.acuity.max) {
    errors = errors.concat(MESSAGES.ACUITY_RANGE)
  }

  return {
    valid: errors.length == 0,
    errors: errors,
    warnings: []
  };
}

interface validateCorrectionArgs {
  correction: CorrectionType,
  priorRxCorrection: CorrectionType,
  rxTypes: RxType
}
export function validateCorrection({correction,
                                    priorRxCorrection,
                                    rxTypes}: validateCorrectionArgs): ValidationResponse {
  if (correctionTypes.indexOf(correction) < 0) {
    return {
      valid: false,
      errors: [MESSAGES.REQUIRED('correction type')],
      warnings: []
    };
  } else {
    const priorRxType = _.get(rxTypes, priorRxCorrection, {label: ''});
    const whitelist = _.get(priorRxType, 'acuity_correction_whitelist', []);
    if (_.get(whitelist, 'length', 0) > 0 && whitelist.indexOf(correction) < 0) {
      return {
        valid: false,
        errors: [MESSAGES.ACUITY_CORRECTION_TYPE(priorRxType.label, whitelist)],
        warnings: []
      };
    }
    else {
      return VALID;
    }
  }
}
